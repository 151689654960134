import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Episode, PastEpisode, Poi } from 'types/episode.types';

export type ContentRoute =
  | {
      key: 'home';
      params?: never;
    }
  | {
      key: 'next-episode-detail';
      params: { episodeId: Episode['id'] };
    }
  | {
      key: 'episode-detail';
      params: { episodeId: PastEpisode['id'] };
    };

export type Modal =
  | { key: 'poi'; params: { poi: Poi } }
  | { key: 'login-error'; params: { code: string } }
  | { key: 'games' }
  | { key: 'quiz' }
  | { key: 'game-rules' }
  | { key: 'select-partner' }
  | { key: 'leaderboard' }
  | { key: 'score-breakdown' };

interface ContentNavigationState {
  contentRoute: ContentRoute;
  modal?: Modal;
  history: ContentRoute[];
  pin?: { isPlacing: boolean; isLocking: boolean };
}

const initialState: ContentNavigationState = {
  contentRoute: { key: 'home' },
  history: [{ key: 'home' }],
};

const contentNavigationSlice = createSlice({
  name: 'content-navigation',
  initialState,
  reducers: {
    CONTENT_NAVIGATE_TO: (
      state,
      { payload }: PayloadAction<ContentNavigationState['contentRoute']>,
    ) => {
      state.contentRoute = payload;
      state.history = [...state.history, payload];
    },
    CONTENT_NAVIGATE_BACK: (state) => {
      if (state.history.length > 1) {
        const latestcontentRoute = state.history[state.history.length - 2];
        state.contentRoute = latestcontentRoute;
        state.history = state.history.slice(0, -1);
      }
    },
    CONTENT_NAVIGATE_HOME: (state) => {
      state.contentRoute = { key: 'home' };
      state.history = [{ key: 'home' }];
    },
    START_PIN_PLACEMENT: (state) => {
      state.pin = { isLocking: false, isPlacing: true };
    },
    LOCK_PIN_PLACEMENT: (state) => {
      state.pin = { isLocking: true, isPlacing: true };
    },
    STOP_PIN_PLACEMENT: (state) => {
      state.pin = undefined;
    },
    MODAL_OPEN: (
      state,
      { payload }: PayloadAction<ContentNavigationState['modal']>,
    ) => {
      state.modal = payload;
    },
    MODAL_CLOSE: (state) => {
      state.modal = undefined;
    },
  },
});

export const { actions: navigationActions } = contentNavigationSlice;

export default contentNavigationSlice.reducer;
