import { useQuery } from '@tanstack/react-query';
import { getTime, parseISO } from 'date-fns';
import _maxBy from 'lodash/maxBy';
import _minBy from 'lodash/minBy';
import { useMemo } from 'react';

import { CacheTime, StaleTime } from 'queries/constants';
import { QueryKeys } from 'queries/QueryKeys';
import { apiService } from 'services';
import { PastEpisode } from 'types/episode.types';

const getEpisodes = async () => {
  const { data } = await apiService.getEpisodes();

  return data;
};

const pastEpisodes: PastEpisode[] = [];

export const useEpisodes = () => {
  const { data, isLoading } = useQuery(
    QueryKeys.episode.all(),
    () => getEpisodes(),
    {
      staleTime: StaleTime.TEN_SECONDS,
      cacheTime: CacheTime.FOREVER,
    },
  );

  const nextEpisode = useMemo(() => {
    const liveEpisode = data?.find((e) => e.isLive && !e.isRevealed);

    if (liveEpisode) {
      return liveEpisode;
    }

    // If the episode isn't live we check for the last episode by start
    const nextEpisodes = data?.filter((e) => !e.isRevealed);

    const nextEpisode = _minBy(nextEpisodes, (e) => {
      return getTime(parseISO(e.startDate));
    });

    return nextEpisode;
  }, [data]);

  const currentEpisode = useMemo(() => {
    const liveEpisodes = data?.filter((e) => e.isLive);

    // We take the last live episode. Just in case they forgot to disable the previous episode
    const liveEpisode = _maxBy(liveEpisodes, (e) => {
      return getTime(parseISO(e.startDate));
    });

    if (!liveEpisode) {
      return nextEpisode;
    }

    return liveEpisode;
  }, [data, nextEpisode]);

  return {
    episodes: nextEpisode ? [nextEpisode] : [],
    nextEpisode,
    currentEpisode,
    pastEpisodes: pastEpisodes,
    isLoading,
  };
};
