import {
  checkboxClasses,
  Components,
  svgIconClasses,
  Theme,
} from '@mui/material';

export const MuiCheckbox: Components<Theme>['MuiCheckbox'] = {
  defaultProps: {
    color: 'primary',
    disableRipple: true,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      padding: 0,
      [`& .${svgIconClasses.root}`]: {
        fill: theme.palette.neutral02[100],
      },

      [`&.${checkboxClasses.checked} .${svgIconClasses.root}`]: {
        fill: theme.palette.primary.main,
      },
    }),
  },
};
