import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { useAuth } from 'hooks';
import { CacheTime } from 'queries/constants';
import { QueryKeys } from 'queries/QueryKeys';
import { apiService } from 'services';
import { PinPlacement } from 'types/user.types';

type Position = { latitude: number; longitude: number };

const getUserPin = async (userId: string, episodeId: string) => {
  const { data } = await apiService.getUserPin(userId, episodeId);

  return data;
};

const postUserPin = async (
  userId: string,
  episodeId: string,
  position: Position,
) => {
  const { data } = await apiService.postUserPin(userId, episodeId, position);

  return data;
};

export const useUserPin = (episodeId = '', enabled = true) => {
  const { userId = '' } = useAuth();
  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery(
    QueryKeys.pin.byEpisode(episodeId),
    () => getUserPin(userId, episodeId),
    {
      enabled: !!episodeId.length && !!userId.length && enabled,
      retry: false,
      cacheTime: CacheTime.FOREVER,
    },
  );

  const { mutate, error: pinSetError } = useMutation<
    PinPlacement,
    AxiosError,
    Position,
    { previousPin?: PinPlacement }
  >((data) => postUserPin(userId, episodeId, data), {
    onMutate: (data) => {
      const previousPin =
        queryClient.getQueryData<PinPlacement>(
          QueryKeys.pin.byEpisode(episodeId),
        ) ?? undefined;

      queryClient.setQueryData<PinPlacement | undefined>(
        QueryKeys.pin.byEpisode(episodeId),
        {
          latitude: data.latitude,
          longitude: data.longitude,
          userId,
          episodeId,
          createdAt: new Date().toISOString(),
        },
      );

      return { previousPin };
    },
    onSuccess: (data) => {
      queryClient.setQueryData<PinPlacement | undefined>(
        QueryKeys.pin.byEpisode(episodeId),
        data,
      );
    },
    onError: (_err, _id, ctx) => {
      queryClient.setQueryData(
        QueryKeys.pin.byEpisode(episodeId),
        ctx?.previousPin ?? null,
      );
    },
  });

  return { pin: data, isLoading, setPin: mutate, pinSetError };
};
