import _debounce from 'lodash/debounce';
import { createContext, ReactNode, useEffect, useState } from 'react';

import { useMap } from './useMap';

export const MapZoomContext = createContext<number>(4);

interface Props {
  children?: ReactNode;
}

export const MapZoomProvider = ({ children }: Props) => {
  const [zoom, setZoom] = useState<number>(4);
  const [map] = useMap();

  useEffect(() => {
    let listener: google.maps.MapsEventListener | undefined;
    if (map) {
      function handleZoomChange() {
        if (map) setZoom(map.getZoom() ?? 0);
      }

      const debouncedZoomChange = _debounce(handleZoomChange, 50);

      listener = map.addListener('zoom_changed', debouncedZoomChange);
    }
    return () => {
      listener?.remove();
    };
  }, [map]);

  return (
    <MapZoomContext.Provider value={zoom}>{children}</MapZoomContext.Provider>
  );
};
