import { differenceInSeconds, parseISO } from 'date-fns';
import { useEffect, useState } from 'react';

import { episodeIsLocked } from 'utils/episode.utils';

import { Episode } from 'types/episode.types';

export const useEpisodeLocked = (episode?: Episode) => {
  const [isLocked, setIsLocked] = useState(
    episode ? episodeIsLocked(episode) : false,
  );

  useEffect(() => {
    let time: NodeJS.Timeout;
    if (episode && !episodeIsLocked(episode) && episode.votesLockedDate) {
      const timeTillLock = differenceInSeconds(
        parseISO(episode.votesLockedDate),
        new Date(),
      );

      // Don't set a timer when too far away (exceeding max time out)
      if (timeTillLock * 1000 >= 2147483647) {
        return;
      }

      time = setTimeout(() => {
        setIsLocked(true);
      }, timeTillLock * 1000);
    } else {
      setIsLocked(true);
    }
    return () => {
      clearTimeout(time);
    };
  }, [episode]);

  return isLocked || (episode && episodeIsLocked(episode));
};
