import { alpha, Box } from '@mui/material';
import { differenceInSeconds, isFuture } from 'date-fns';
import { useEffect, useState } from 'react';

import { IcoLockCircleGrey, IcoLockCirclePrimary } from 'components/@icons';

interface Props {
  lockDate: Date;
  startDate: Date;
  glow: 'white' | 'primary';
  showLock?: boolean;
}

const VoteCountDown = ({ lockDate, startDate, showLock, glow }: Props) => {
  const [difference, setDifference] = useState(
    differenceInSeconds(lockDate, new Date()),
  );

  useEffect(() => {
    // Trigger re-render every second
    const interval = setInterval(() => {
      const diff = differenceInSeconds(lockDate, new Date());

      setDifference(diff);

      if (diff <= 0) {
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [lockDate, startDate]);

  if (difference <= 0) {
    return <IcoLockCircleGrey width={20} height={20} />;
  }

  const progress =
    100 -
    Math.max(
      Math.min(
        (difference / differenceInSeconds(lockDate, startDate)) * 100,
        100,
      ),
      0,
    );

  if (isFuture(startDate)) {
    return null;
  }

  return (
    <Box
      bgcolor="primary.main"
      border={2}
      borderColor="neutral01.100"
      borderRadius="50%"
      width={21}
      height={21}
      position="relative"
      flexShrink={0}
      boxShadow={(theme) =>
        `0px 0px 12px 6px ${
          glow === 'white'
            ? theme.palette.neutral02[100]
            : alpha(theme.palette.primary.main, 0.4)
        }`
      }
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        position="absolute"
        top={0}
        right={0}
        left={0}
        bottom={0}
      >
        <Box
          width={13}
          height={13}
          borderRadius="50%"
          sx={(theme) => ({
            background: `conic-gradient(${theme.palette.primary.main} ${progress}%, ${theme.palette.neutral01[100]} 0)`,
          })}
        />
      </Box>
      {showLock && (
        <Box position="absolute" right={-4} bottom={-17}>
          <IcoLockCirclePrimary width={10} height={10} />
        </Box>
      )}
    </Box>
  );
};

export default VoteCountDown;
