import { useQuery } from '@tanstack/react-query';

import { CacheTime } from 'queries/constants';
import { QueryKeys } from 'queries/QueryKeys';
import { apiService } from 'services';

const getGameRules = async () => {
  const { data } = await apiService.getGameRules();

  return data;
};

export const useGameRules = () => {
  const { data, isFetching } = useQuery(
    QueryKeys.settings.gameRules(),
    () => getGameRules(),
    {
      cacheTime: CacheTime.FOREVER,
    },
  );

  return {
    data,
    isFetching,
  };
};
