import config from 'config';

import { inIframe } from 'utils/window.utils';

import { startAppListening } from 'services/redux/listenerMiddleware';

import { authActions } from './auth.slice';

export default function startAuthListeners() {
  startAppListening({
    predicate: (action) => action.type === authActions.LOGIN_START.type,
    effect: (_, listenerApi) => {
      listenerApi.cancelActiveListeners();
      if (window.top && inIframe()) {
        window.top.location.href = `https://login2.vtm.be/authorize?client_id=${config.VTM_CLIENT_ID}&prompt=login&response_type=code&redirect_uri=${config.IFRAME_CLIENT_URL}&scope=openid email profile`;
      } else {
        window.location.href = `https://login2.vtm.be/authorize?client_id=${config.VTM_CLIENT_ID}&prompt=login&response_type=code&redirect_uri=${config.CLIENT_URL}&scope=openid email profile`;
      }
    },
  });
}
