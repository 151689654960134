import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoCheckboxWrong = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
    <rect width={20} height={20} fill="#F21600" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.5858 10L5.29291 6.70712L6.70712 5.29291L10 8.5858L13.2929 5.29291L14.7071 6.70712L11.4142 10L14.7071 13.2929L13.2929 14.7071L10 11.4142L6.70712 14.7071L5.29291 13.2929L8.5858 10Z"
      fill="black"
    />
  </svg>
);
const Memo = memo(SvgIcoCheckboxWrong);
export default Memo;
