export enum EventName {
  PartnerAxa = 'bx-partner-1',
  PartnerBrusselsAirlines = 'bx-partner-2',
  PastEpisode = 'bx-episode',
  NextEpisode = 'bx-gamecenter',
  EpisodeMap = 'bx-episode-map',
  PinPlace = 'bx-game-pin',
  PastEpisodePlayAnonymous = 'bx-gamecenter-episode',
  PastEpisodePlay = 'bx-play-game',
  PoiMap = 'bx-poi-map',
  PoiList = 'bx-poi-list',
  PastEpisodeVtmGo = 'vtm-go-episode',
  GuessArea = 'bx-game-mini',
  ChoosePartner = 'bx-game-candidate',
  PinAdd = 'bx-game-pin-add',
  PinChange = 'bx-game-pin-change',
  PinLock = 'bx-game-pin-confirm',
  Guess = 'bx-game-mini-geoguess',
  Quiz = 'bx-game-mini-quiz',
  QuizAnswer = 'bx-game-mini-quiz-{count}',
  GuessAnswer = 'bx-game-mini-geoguess-{count}',
  PastEpisodeVtmGoTips = 'vtm-go-cpbd',
  PartnerSelect = 'bx-game-candidate-select',
  Rules = 'bx-tutorial',
  ShareGuess = 'bx-share-guess',
  ShareScore = 'bx-share-score',
  Leaderboard = 'bx-leaderboard',

  // TODO: 👇
  QuizVtmGo = 'vtm-go-cpbd-quiz',
}

export enum PageView {
  MiniGames = 'mini-games',
  Quiz = 'mini-game-quiz',
  Poi = 'poi',
  Partner = 'partner-in-crime',
  Guess = 'mini-game-geoguess',
  PastEpisode = 'episode',
  NextEpisode = 'game',
  Program = 'program',
  Map = 'map-game',
  Rules = 'tutorial',
  Leaderboard = 'leaderboard',
}

export const DATALAYER_NAME = 'dataLayer';
