import { CardActionArea, Stack, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { AnimatedButton } from 'components/@common';
import { IcoLock } from 'components/@icons';

import { CardStyled, IconWrap } from './GameCard.style';

interface Props {
  icon: React.ReactNode;
  title: string;
  subtitle: string;
  button: {
    label: string;
    onClick(): void;
  };
  finished?: boolean;
}

const GameCard: React.FC<Props> = ({
  icon,
  title,
  subtitle,
  button,
  finished,
}) => {
  return (
    <CardStyled finished={finished}>
      <CardActionArea
        disabled={finished}
        onClick={button.onClick}
        sx={(theme) => ({
          p: { xs: 5, md: theme.spacing(7, 5) },
          boxShadow: 'none',
        })}
      >
        <IconWrap height={{ xs: 52, md: 102 }} finished={finished}>
          {finished && (
            <Stack
              justifyContent="center"
              alignItems="center"
              position="absolute"
              width="100%"
              height="100%"
              zIndex={1}
            >
              <IcoLock className="lock" width={54} height={73} />
            </Stack>
          )}
          {icon}
        </IconWrap>
        <Stack mb={4} alignItems="center">
          <Typography
            variant="h4"
            color={finished ? 'neutral01.500' : 'neutral02.100'}
          >
            <FormattedMessage id={title} />
          </Typography>
          <Typography color={finished ? 'neutral01.500' : 'neutral02.100'}>
            <FormattedMessage id={subtitle} />
          </Typography>
        </Stack>
        <AnimatedButton disabled={finished} fullWidth>
          <FormattedMessage id={button.label} />
        </AnimatedButton>
      </CardActionArea>
    </CardStyled>
  );
};

export default GameCard;
