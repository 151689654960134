import { useEpisodes } from 'queries';
import { usePolling } from 'queries/episodes/usePolling';

const Poller = () => {
  const { currentEpisode } = useEpisodes();

  usePolling(currentEpisode);

  return null;
};

export default Poller;
