import { Options } from 'html-to-image/lib/types';

export const createImage = async (id: string) => {
  const data = document.getElementById(`share-${id}`);

  if (data) {
    data.style.display = 'block';

    let toJpg = (await import('html-to-image')).toJpeg;

    const options: Options = {
      pixelRatio: 1,
      width: 1080,
      height: 1920,
      canvasWidth: 1080,
      canvasHeight: 1920,
      skipFonts: false,
      quality: 0.8,
      includeQueryParams: true,
    };

    let image = await toJpg(data, options);

    // Weird issue for mobile https://github.com/bubkoo/html-to-image/issues/361
    image = await toJpg(data, options);
    image = await toJpg(data, options);

    data.style.display = 'none';

    return image;
  }
};

export const shareImage = async (image: string, name: string) => {
  try {
    const blob = await (await fetch(image)).blob();
    const file = new File([blob], `bestemming-x-share-${name}.jpg`, {
      type: 'image/jpeg',
      lastModified: new Date().getTime(),
    });
    if (file) {
      navigator?.share &&
        navigator
          .share({
            files: [file],
          })
          .catch((e: Error) => {
            console.error(e);
          });
    }
  } catch {}
};

export const downloadImage = async (
  image: string,
  name: string,
  ext = 'jpg',
) => {
  try {
    const blob = await (await fetch(image)).blob();
    if (blob) {
      var element = document.createElement('a');
      element.setAttribute('href', URL.createObjectURL(blob));
      element.setAttribute('target', '_blank');
      element.setAttribute('download', `bestemming-x-share-${name}.${ext}`);

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    }
  } catch {}
};
