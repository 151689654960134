import { Components, Theme } from '@mui/material';

import { inIframe } from 'utils/window.utils';

import { FOOTER_HEIGHT, VTM_HEADER_HEIGHT } from 'constants/ux.constants';

export const MuiDialog: Components<Theme>['MuiDialog'] = {
  defaultProps: {
    maxWidth: 'md',
    slotProps: {
      backdrop: {
        sx: {
          maxHeight: inIframe()
            ? `calc(100vh - ${FOOTER_HEIGHT}px)`
            : undefined,
        },
      },
    },
  },
  styleOverrides: {
    root: ({ theme }) => ({
      fontFamily: "'Nunito Sans', sans-serif",
      [theme.breakpoints.down('md')]: {
        zIndex: 5001,
        maxHeight: 'calc(100vh - 24px)',
      },
      top: inIframe() ? VTM_HEADER_HEIGHT : 24,
      maxHeight: inIframe()
        ? `calc(100vh - ${FOOTER_HEIGHT + VTM_HEADER_HEIGHT + 10}px)`
        : `calc(100vh - 48px)`,
    }),
    paper: ({ theme }) => ({
      backgroundColor: theme.palette.neutral01[200],
      width: '100%',
      maxHeight: '100%',
      margin: 0,
      overflow: 'visible',
    }),
  },
};
