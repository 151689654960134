import { Button } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useAnalytics, usePrevious } from 'hooks';
import { EventName } from 'types/analytics.types';

import { IcoShare } from 'components/@icons';

import { downloadImage, shareImage } from '../share.utils';
import ShareScore from '../ShareScore/ShareScore';

interface Props {
  score: number;
}

const ShareScoreButton = ({ score }: Props) => {
  const { trackEvent } = useAnalytics();
  const id = `${score}`;
  const prevId = usePrevious(id);

  const [render, setRender] = useState<string>();
  const renderedImage = useRef<{ image: string; id: string }>();

  useEffect(() => {
    if (prevId !== id && prevId !== undefined) {
      renderedImage.current = undefined;
    }
  }, [id, prevId]);

  const handleImageComplete = (img: string, score: Props['score']) => {
    const id = `${score}`;

    renderedImage.current = {
      image: img,
      id,
    };

    setRender(undefined);

    const canShare = !!navigator.share;
    if (canShare) {
      shareImage(img, 'score');
    } else {
      downloadImage(img, 'score');
    }
  };

  const handleClick = () => {
    trackEvent({ eventName: EventName.ShareScore });
    if (renderedImage.current?.id !== id) {
      setRender(id);
    } else if (renderedImage.current) {
      handleImageComplete(renderedImage.current.image, score);
    }
  };

  return (
    <>
      {render && (
        <ShareScore
          score={score}
          onComplete={(img) => handleImageComplete(img, score)}
        />
      )}
      <Button
        onClick={handleClick}
        variant="outlined"
        startIcon={<IcoShare height={16} />}
      >
        <FormattedMessage id="home.score.button.share" />
      </Button>
    </>
  );
};

export default ShareScoreButton;
