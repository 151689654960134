import {
  Box,
  Divider,
  Grid,
  Link,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { motion, Transition, Variants } from 'framer-motion';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import { useScoreBreakDown } from 'queries';
import { navigationActions } from 'store/navigation/navigation.slice';

import { EpisodeBreakdown, Points } from 'components/@score';
import { ShareScoreButton } from 'components/@share';
import LoadingState from 'components/@states/LoadingState';

const animationVariant: Variants = {
  initial: { opacity: 0, y: 20 },
  animate: {
    opacity: 1,
    y: 0,
  },
};

const transition = (index: number): Transition => {
  return { delay: 0.5 + index * 0.3, duration: 0.7, ease: 'easeOut' };
};

const ScoreBreakdownContent = () => {
  const { data, totalScore, isFetching } = useScoreBreakDown();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleOpenGameRules = () => {
    dispatch(navigationActions.MODAL_OPEN({ key: 'game-rules' }));
  };

  return (
    <motion.div initial="initial" animate="animate">
      <Stack flex={1}>
        <motion.div variants={animationVariant} transition={transition(0)}>
          <Typography variant={isMobile ? 'h4' : 'h2'} mb={5}>
            <FormattedMessage id="score.breakdown.modal.title" />
          </Typography>
        </motion.div>

        {isFetching ? (
          <LoadingState />
        ) : (
          <motion.div variants={animationVariant} transition={transition(1)}>
            <Grid
              container
              wrap="nowrap"
              direction={{ xs: 'column-reverse', md: 'row' }}
              spacing={{ xs: 4, md: 5 }}
            >
              <Grid item xs={12} md={7}>
                <Stack gap={4}>
                  {data?.breakdown.map((breakdown, index) => (
                    <Box key={breakdown.episodeId}>
                      <Typography color="primary.main" variant="paragraph2">
                        <FormattedMessage id="home.card.episode.title" />{' '}
                        {data.breakdown.length - index}
                      </Typography>
                      <Divider sx={{ margin: '10px 0' }} />
                      <EpisodeBreakdown breakdown={breakdown} />
                    </Box>
                  ))}
                </Stack>
                <Divider sx={{ mt: '10px' }} />
                <Link mt={3} onClick={handleOpenGameRules}>
                  <FormattedMessage id="home.link.game_rules" />
                </Link>
              </Grid>
              <Grid
                item
                display="flex"
                flexDirection="column"
                alignItems="center"
                xs={12}
                md={5}
              >
                <Points width={250} score={totalScore} />
                <Box mt={4}>
                  <ShareScoreButton score={totalScore} />
                </Box>
              </Grid>
            </Grid>
          </motion.div>
        )}
      </Stack>
    </motion.div>
  );
};

export default ScoreBreakdownContent;
