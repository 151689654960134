import { Box, ListItem, Typography } from '@mui/material';
import { FormattedNumber } from 'react-intl';

import { useAuth } from 'hooks';
import { LeaderboardUser } from 'types/leaderboard.types';

interface Props {
  user: LeaderboardUser;
}

const LeaderboardListItem = ({ user }: Props) => {
  const { userId } = useAuth();

  const isCurrentUser = userId === user.id;
  return (
    <ListItem disableGutters>
      <Box
        display="flex"
        justifyContent="space-between"
        width="100%"
        color={isCurrentUser ? 'brand01.100' : 'neutral02.100'}
      >
        <Typography
          variant="paragraph1"
          textTransform="capitalize"
          fontSize={isCurrentUser ? 20 : 16}
        >
          {user.position}. {user.firstName} {user.lastNameInitials}
        </Typography>
        <Typography variant="paragraph1" fontSize={isCurrentUser ? 20 : 16}>
          <FormattedNumber value={user.score} />
        </Typography>
      </Box>
    </ListItem>
  );
};

export default LeaderboardListItem;
