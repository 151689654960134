import { Button, ButtonProps, styled } from '@mui/material';
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import ReactSlickSlider, {
  CustomArrowProps,
  Settings as SliderProps,
} from 'react-slick';

interface ArrowProps extends CustomArrowProps, Omit<ButtonProps, 'onClick'> {}

export const ArrowButton = ({
  currentSlide,
  slideCount,
  children,
  ...props
}: ArrowProps) => (
  <Button variant="icon" color="secondary" {...props}>
    {children}
  </Button>
);

const SliderContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  '.slick-track': {
    display: 'flex',
  },
  '.slick-list': {},
  '.slick-slide': {
    marginRight: theme.spacing(1),
  },
  '.slick-list, .slick-track': {
    touchAction: 'pan-y',
  },
  '.slick-disabled': {
    display: 'none !important',
  },
}));

export type DefaultSliderProps = SliderProps;

/**
 * Threshold from which mouse movement with pressed mouse button
 * is considered a drag instead of a click.
 */
const MoveDragThreshold = 10;

function useDragDetection(): {
  handleMouseDown: () => void;
  dragging: boolean;
} {
  const [mouseDown, setMouseDown] = useState(false);
  const [dragging, setDragging] = useState(false);

  useEffect(() => {
    let mouseMove = 0;

    function handleMouseUp(): void {
      setMouseDown(false);
    }

    function handleMouseMove(e: MouseEvent): void {
      mouseMove += Math.abs(e.movementX) + Math.abs(e.movementY);

      setDragging(mouseMove > MoveDragThreshold);
    }

    if (mouseDown) {
      document.addEventListener('mouseup', handleMouseUp);
      document.addEventListener('mousemove', handleMouseMove);
    }

    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [mouseDown]);

  function handleMouseDown(): void {
    setMouseDown(true);
    setDragging(false);
  }

  return {
    handleMouseDown,
    dragging,
  };
}

const Slider = (
  props: PropsWithChildren<DefaultSliderProps>,
): React.ReactElement => {
  const { children, ...sliderProps } = props;

  const slickRef = useRef<ReactSlickSlider>(null);

  const { handleMouseDown, dragging } = useDragDetection();

  function handleChildClick(
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ): void {
    if (dragging) {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  return (
    <SliderContainer>
      <ReactSlickSlider {...sliderProps} ref={slickRef}>
        {React.Children.map(children, (child) => (
          <div
            onMouseDownCapture={handleMouseDown}
            onClickCapture={handleChildClick}
          >
            {child}
          </div>
        ))}
      </ReactSlickSlider>
    </SliderContainer>
  );
};

export type { SliderProps };
export default Slider;
