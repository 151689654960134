import React from 'react';
import { RouteObject } from 'react-router-dom';

export type Route = Omit<RouteObject, 'element'>;

export const createRoute = (
  Component: React.LazyExoticComponent<React.FC>,
  config?: Route,
) => {
  // @ts-ignore
  const routeObject: RouteObject = {
    ...config,
    element: <Component />,
  };

  return routeObject;
};
