import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import { isDev } from 'utils/env.utils';

import { gtmParams } from 'services/analytics';
import history from 'services/history';
import IntlSelectorProvider from 'services/i18n';
import { queryClient } from 'services/react-query';
import storeConfig from 'store';
import theme from 'theme';

import Pages from 'pages/Pages';

import { RootBoundary, RouteBoundary } from 'components/@boundaries';
import { Poller } from 'components/@common';
import { MapProvider } from 'components/@map';
import { ModalWrapper } from 'components/@modal';
import LoadingState from 'components/@states/LoadingState';

const App = () => (
  <RootBoundary>
    <GTMProvider state={gtmParams}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ReduxProvider store={storeConfig.store}>
            <PersistGate
              loading={<LoadingState />}
              persistor={storeConfig.persistor}
              onBeforeLift={storeConfig.initApp}
            >
              <IntlSelectorProvider>
                <HelmetProvider>
                  <RouteBoundary>
                    {/* @ts-ignore */}
                    <HistoryRouter history={history}>
                      <MapProvider>
                        <ModalWrapper>
                          <Poller />
                          <Pages />
                        </ModalWrapper>
                      </MapProvider>
                    </HistoryRouter>
                  </RouteBoundary>
                </HelmetProvider>
              </IntlSelectorProvider>
            </PersistGate>
          </ReduxProvider>
        </ThemeProvider>
        {isDev() && (
          <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        )}
      </QueryClientProvider>
    </GTMProvider>
  </RootBoundary>
);

export default App;
