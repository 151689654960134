import { Link } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useAnalytics, usePrevious } from 'hooks';
import { EventName } from 'types/analytics.types';

import { IcoShare } from 'components/@icons';

import { downloadImage, shareImage } from '../share.utils';
import ShareGuess from '../ShareGuess/ShareGuess';

interface Props {
  position: { lat: number; lng: number };
}

const ShareGuessButton = ({ position }: Props) => {
  const { trackEvent } = useAnalytics();
  const id = `${position.lat}-${position.lng}`;
  const prevId = usePrevious(id);

  const [render, setRender] = useState<string>();
  const renderedImage = useRef<{ image: string; id: string }>();

  useEffect(() => {
    if (prevId !== id && prevId !== undefined) {
      renderedImage.current = undefined;
    }
  }, [id, prevId]);

  const handleImageComplete = (img: string, position: Props['position']) => {
    const id = `${position.lat}-${position.lng}`;

    renderedImage.current = {
      image: img,
      id,
    };

    setRender(undefined);

    const canShare = !!navigator.share;
    if (canShare) {
      shareImage(img, 'guess');
    } else {
      downloadImage(img, 'guess');
    }
  };

  const handleClick = () => {
    trackEvent({ eventName: EventName.ShareGuess });
    if (renderedImage.current?.id !== id) {
      setRender(id);
    } else if (renderedImage.current) {
      handleImageComplete(renderedImage.current.image, position);
    }
  };

  return (
    <>
      {render && (
        <ShareGuess
          position={position}
          onComplete={(img) => handleImageComplete(img, position)}
        />
      )}
      <Link
        component="button"
        onClick={handleClick}
        sx={{ alignItems: 'center', display: 'flex' }}
      >
        <IcoShare fontSize={16} style={{ marginRight: 8 }} />
        <FormattedMessage id="detail.next.pin.share.button" />
      </Link>
    </>
  );
};

export default ShareGuessButton;
