import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoClose = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M18 6L6 18"
      stroke="white"
      strokeWidth={3.21429}
      strokeLinecap="square"
      strokeLinejoin="bevel"
    />
    <path
      d="M6 6L18 18"
      stroke="white"
      strokeWidth={3.21429}
      strokeLinecap="square"
      strokeLinejoin="bevel"
    />
  </svg>
);
const Memo = memo(SvgIcoClose);
export default Memo;
