import { Box, Link, styled } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { Ico6Play } from 'components/@icons';

const ButtonStyled = styled(Link)(({ theme }) => ({
  borderRadius: 47,
  padding: theme.spacing(0, 3),
  paddingTop: 2,
  backgroundColor: '#0B0426',
  color: '#fff',
  height: 38,
  fontSize: 10,
  fontWeight: 700,
  lineHeight: '5px',
  textTransform: 'none',
  textAlign: 'center',
  fontFamily: 'Nunito Sans',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginRight: 'auto',
  '&:hover': {
    textDecoration: 'none',
    color: '#fff',
    backgroundColor: '#0B0426',
    boxShadow: 'rgb(0 0 0 / 8%) 0px 0.3125rem 0.625rem 0px',
  },
}));

interface Props {
  onClick?: () => void;
  href: string;
  rewatch?: boolean;
}

const SixPlayButton = ({ onClick, href, rewatch }: Props) => {
  return (
    <ButtonStyled
      href={href}
      onClick={onClick}
      target="_blank"
      underline="none"
    >
      <FormattedMessage
        id={
          rewatch ? 'general.vtmgo.rewatch.label' : 'general.vtmgo.watch.label'
        }
      />

      <Box mt="4px">
        <Ico6Play width={60} height={15} />
      </Box>
    </ButtonStyled>
  );
};

export default SixPlayButton;
