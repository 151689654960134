import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoNumber = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 27 26" fill="none" {...props}>
    <path
      d="M26.627 6.61719V10.8717L24.4402 12.999L26.627 15.1263V19.3808L20.0666 12.999L26.627 6.61719Z"
      fill="currentColor"
    />
    <path
      d="M0.627172 19.3808V15.1263L2.81395 12.999L0.627172 10.8717V6.61719L7.1875 12.999L0.627172 19.3808Z"
      fill="currentColor"
    />
    <path
      d="M7.06587 0H11.4394L13.6262 2.12727L15.813 0H20.1865L13.6262 6.38182L7.06587 0Z"
      fill="currentColor"
    />
    <path
      d="M20.1865 25.999H15.813L13.6262 23.8717L11.4394 25.999H7.06587L13.6262 19.6172L20.1865 25.999Z"
      fill="currentColor"
    />
  </svg>
);

const Memo = memo(SvgIcoNumber);
export default Memo;
