/* eslint-disable @typescript-eslint/no-explicit-any */
import _isEqual from 'lodash/isEqual';

export const useAnalytics = () => {
  const trackPageView = (_: any) => {
    // Nothing
  };

  const trackEvent = (_: any) => {
    // Nothing
  };

  return {
    trackEvent,
    trackPageView,
  };
};
