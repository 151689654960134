import { Components, Theme } from '@mui/material';

export const MuiDialogContent: Components<Theme>['MuiDialogContent'] = {
  defaultProps: {},
  styleOverrides: {
    root: ({ theme }) => ({
      zIndex: 25,
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(3, 2),
      overflowX: 'hidden',
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(10),
        paddingTop: theme.spacing(12),
      },
    }),
  },
};
