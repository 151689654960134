import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  CircularProgress,
  styled,
} from '@mui/material';
import { motion } from 'framer-motion';
import { ReactNode } from 'react';

import { IcoCorner } from 'components/@icons/custom';

const cornerLeftMotion = {
  hover: {
    scale: [0.5, 0.5, 1],
    translateX: [5, -12, -12],
    translateY: [5, -12, -12],
    transition: {
      type: 'spring',
      stiffness: 100,
      damping: 40,
    },
  },
  hoverSmall: {
    scale: [0.5, 0.5, 0.8],
    translateX: [5, -10, -10],
    translateY: [5, -8, -8],
    transition: {
      type: 'spring',
      stiffness: 100,
      damping: 40,
    },
  },
};

const cornerRightMotion = {
  hover: {
    scale: [-0.5, -0.5, -1],
    translateX: [-5, 12, 12],
    translateY: [-5, 12, 12],
    transition: {
      type: 'spring',
      stiffness: 100,
      damping: 40,
    },
  },
  hoverSmall: {
    scale: [-0.5, -0.5, -0.8],
    translateX: [-5, 10, 10],
    translateY: [-5, 10, 10],
    transition: {
      type: 'spring',
      stiffness: 100,
      damping: 40,
    },
  },
};

const CornerLeft = styled(motion.div)({
  position: 'absolute',
  top: 0,
  left: 2,
});

const CornerRight = styled(motion.div)({
  position: 'absolute',
  bottom: 0,
  right: 2,
  transform: 'scale(-1)',
});

const MotionWrap = styled(motion.div)({
  position: 'relative',
  width: '100%',
  margin: 'auto',
  overflow: 'visible',
});

interface Props extends ButtonProps {
  children?: ReactNode;
  sx?: BoxProps['sx'];
  loading?: boolean;
}

const AnimatedButton = ({
  children,
  sx,
  size,
  disabled,
  fullWidth,
  loading,
  ...rest
}: Props) => {
  return (
    <Box width={fullWidth ? '100%' : 'fit-content'} sx={sx} overflow="visible">
      <MotionWrap
        key={`${disabled}`}
        whileHover={
          !!disabled ? undefined : size === 'small' ? 'hoverSmall' : 'hover'
        }
        whileFocus={
          !!disabled ? undefined : size === 'small' ? 'hoverSmall' : 'hover'
        }
      >
        <CornerLeft
          variants={cornerLeftMotion}
          initial={{ scale: 0.5, translateX: 5, translateY: 5 }}
        >
          <IcoCorner />
        </CornerLeft>
        <CornerRight
          variants={cornerRightMotion}
          initial={{ scale: -0.5, translateX: -5, translateY: -5 }}
        >
          <IcoCorner />
        </CornerRight>
        {rest.onClick ? (
          <Button
            variant="contained"
            color="primary"
            size={size}
            disabled={disabled}
            fullWidth={fullWidth}
            {...rest}
          >
            <Box
              visibility={loading ? 'hidden' : 'visible'}
              display="flex"
              alignItems="center"
            >
              {children}
            </Box>
            {loading && (
              <CircularProgress size={16} sx={{ position: 'absolute' }} />
            )}
          </Button>
        ) : (
          <Button
            component="div"
            variant="contained"
            color="primary"
            size={size}
            disabled={disabled}
            fullWidth={fullWidth}
          >
            <Box
              visibility={loading ? 'hidden' : 'visible'}
              display="flex"
              alignItems="center"
            >
              {children}
            </Box>
            {loading && (
              <CircularProgress size={16} sx={{ position: 'absolute' }} />
            )}
          </Button>
        )}
      </MotionWrap>
    </Box>
  );
};

export default AnimatedButton;
