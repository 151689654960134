import { Box } from '@mui/material';

import { IcoPlay } from 'components/@icons';

interface Props {
  size?: number;
}

const VideoPlayOverlay = ({ size = 40 }: Props) => {
  return (
    <Box
      position="absolute"
      top={0}
      left={0}
      right={0}
      bottom={0}
      justifyContent="center"
      alignItems="center"
      display="flex"
    >
      <Box
        height={size}
        width={size}
        bgcolor="neutral02.100"
        justifyContent="center"
        alignItems="center"
        display="flex"
      >
        <IcoPlay fontSize={22} />
      </Box>
    </Box>
  );
};

export default VideoPlayOverlay;
