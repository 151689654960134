import { Stack, Typography } from '@mui/material';
import { motion, Transition, Variants } from 'framer-motion';
import { FormattedMessage } from 'react-intl';

import { useGameRules } from 'queries/settings';

import { PrismicText } from 'components/@common';
import LoadingState from 'components/@states/LoadingState';

const animationVariant: Variants = {
  initial: { opacity: 0, y: 20 },
  animate: {
    opacity: 1,
    y: 0,
  },
};

const transition = (index: number): Transition => {
  return { delay: 0.5 + index * 0.3, duration: 0.7, ease: 'easeOut' };
};

const GameRulesContent = () => {
  const { data, isFetching } = useGameRules();

  return (
    <motion.div
      initial="initial"
      animate="animate"
      style={{ flex: 1, display: 'flex' }}
    >
      <Stack flex={1}>
        <motion.div variants={animationVariant} transition={transition(0)}>
          <Typography variant="h2" mb={{ xs: 3, md: 4 }}>
            <FormattedMessage id="rules.modal.title" />
          </Typography>
        </motion.div>
        {isFetching ? (
          <LoadingState />
        ) : (
          <motion.div
            variants={animationVariant}
            transition={transition(1)}
            style={{ flex: 1 }}
          >
            <PrismicText text={data?.value} />
          </motion.div>
        )}
      </Stack>
    </motion.div>
  );
};

export default GameRulesContent;
