import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { CacheTime, StaleTime } from 'queries/constants';
import { QueryKeys } from 'queries/QueryKeys';
import { apiService } from 'services';
import { getUserId } from 'store/auth/auth.selectors';

const getMiniGames = async (userId: string, episodeId: string) => {
  const { data } = await apiService.getMiniGames(userId, episodeId);

  return data;
};

export const useMiniGames = (episodeId?: string, enabled = true) => {
  const userId = useSelector(getUserId) ?? '';
  const episode = episodeId ?? '';
  const { data, isLoading } = useQuery(
    QueryKeys.miniGames.byEpisode(episode),
    () => getMiniGames(userId, episode),
    {
      enabled: !!userId.length && !!episode.length && enabled,
      staleTime: StaleTime.TEN_SECONDS,
      cacheTime: CacheTime.FOREVER,
    },
  );

  return {
    data,
    isLoading,
  };
};
