import { Components, tabClasses, Theme } from '@mui/material';

export const MuiTab: Components<Theme>['MuiTab'] = {
  defaultProps: {},
  styleOverrides: {
    root: ({ theme }) => ({
      fontFamily: 'Eurostile Extended',
      fontSize: 14,
      color: theme.palette.neutral02[100],
      [`&.${tabClasses.selected}`]: {
        color: theme.palette.brand01[100],
      },
      backgroundColor: theme.palette.neutral01[100],
      margin: theme.spacing(0, 2),
      [theme.breakpoints.up('md')]: {
        margin: theme.spacing(0, 8),
      },
    }),
  },
};
