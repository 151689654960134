import { Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { AnimatedButton } from 'components/@common';
import { IcoQuestion } from 'components/@icons';

import { Card } from './UpcomingCard.style';

interface Props {
  onClick?: () => void;
  loading?: boolean;
}

const UpcomingCard = ({ onClick, loading }: Props) => {
  return (
    <Card onClick={onClick} disabled={loading}>
      <Typography variant="h5Secondary" textTransform="uppercase">
        <FormattedMessage id="home.card.play.title" />
      </Typography>
      <IcoQuestion fontSize={30} style={{ flexShrink: 0 }} />
      <Typography variant="paragraph2">
        <FormattedMessage id="home.card.play.subtitle" />
      </Typography>
      <AnimatedButton
        size="small"
        fullWidth
        disabled={loading}
        loading={loading}
      >
        <FormattedMessage id="home.card.play.button" />
      </AnimatedButton>
    </Card>
  );
};

export default UpcomingCard;
