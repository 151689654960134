import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { useAuth } from 'hooks';
import { QueryKeys } from 'queries/QueryKeys';
import { apiService } from 'services';
import { Participant, SelectPartnerParams } from 'types/episode.types';

const selectPartner = async (variables: SelectPartnerParams) => {
  const { data } = await apiService.selectPartner(variables);

  return data;
};

export const useSelectPartner = () => {
  const { userId = '' } = useAuth();
  const queryClient = useQueryClient();
  const { mutate } = useMutation<
    Participant,
    AxiosError,
    Pick<SelectPartnerParams, 'episodeId' | 'participant'>,
    { prevPartner: Participant | undefined }
  >(
    ({ episodeId = '', participant }) =>
      selectPartner({ userId, episodeId, participant }),
    {
      onMutate: async ({ episodeId, participant }) => {
        await queryClient.cancelQueries(QueryKeys.episode.partner(episodeId));

        const prevPartner = queryClient.getQueryData<Participant>(
          QueryKeys.episode.partner(episodeId),
        );

        queryClient.setQueryData<Participant>(
          QueryKeys.episode.partner(episodeId),
          participant,
        );

        return { prevPartner };
      },
      onError: (_error, { episodeId }, context) => {
        queryClient.setQueryData(
          QueryKeys.episode.partner(episodeId),
          context?.prevPartner ?? null,
        );
      },
    },
  );

  return {
    selectPartner: mutate,
  };
};
