import { Box, SxProps } from '@mui/material';
import { BoxProps } from '@mui/system';
import { motion } from 'framer-motion';
import { useState } from 'react';

interface Props {
  src?: string;
  objectFit?: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down';
  objectPosition?: string;
  bgcolor?: string;
  width: BoxProps['width'];
  height: BoxProps['height'];
  alt?: string;
  sx?: SxProps;
}

const FadeImage = ({
  src,
  objectFit = 'cover',
  objectPosition = 'center',
  width,
  height,
  bgcolor = 'neutral01.300',
  alt,
  sx,
}: Props) => {
  const [imageLoading, setImageLoading] = useState(true);
  const [updatedSrc, setUpdatedSrc] = useState<string>();
  const imageLoaded = () => {
    setImageLoading(false);
  };

  return (
    <Box
      width={width}
      height={height}
      bgcolor={bgcolor}
      overflow="hidden"
      sx={{ ...sx }}
    >
      <motion.img
        initial={{ opacity: 0 }}
        style={{ objectFit, objectPosition }}
        animate={{ opacity: imageLoading ? 1 : 1 }}
        transition={{ opacity: { duration: 0.3 } }}
        onLoad={imageLoaded}
        onError={() => {
          if (src && !updatedSrc) {
            // Image fails on auto=format on native webview. If load fails once we try to update the image
            setUpdatedSrc(src?.replace('format', ''));
          }
        }}
        width="100%"
        height="100%"
        alt={alt}
        src={updatedSrc ?? src}
      />
    </Box>
  );
};

export default FadeImage;
