import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoBinocular = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 34 34" fill="none" {...props}>
    <path
      d="M7.28432 32.7874C10.6375 32.7874 13.3557 30.0691 13.3557 26.716C13.3557 23.3628 10.6375 20.6445 7.28432 20.6445C3.93116 20.6445 1.21289 23.3628 1.21289 26.716C1.21289 30.0691 3.93116 32.7874 7.28432 32.7874Z"
      stroke="white"
      strokeWidth={2.64935}
      strokeLinecap="round"
      strokeLinejoin="bevel"
    />
    <path
      d="M13.355 7.28667V3.64382C13.355 2.99972 13.0992 2.382 12.6437 1.92656C12.1883 1.47111 11.5706 1.21525 10.9265 1.21525H8.06076C7.48917 1.20485 6.93224 1.39643 6.488 1.75626C6.04377 2.11609 5.74071 2.6211 5.63219 3.18239L1.33362 25.501"
      stroke="white"
      strokeWidth={2.64935}
      strokeLinecap="square"
      strokeLinejoin="bevel"
    />
    <path
      d="M10.9263 13.3574H23.0691"
      stroke="#EBD457"
      strokeWidth={2.64935}
      strokeLinecap="square"
      strokeLinejoin="bevel"
    />
    <path
      d="M13.3413 19.127V26.2103"
      stroke="white"
      strokeWidth={2.64935}
      strokeLinecap="square"
      strokeLinejoin="bevel"
    />
    <path
      d="M26.7112 32.7874C30.0644 32.7874 32.7826 30.0691 32.7826 26.716C32.7826 23.3628 30.0644 20.6445 26.7112 20.6445C23.358 20.6445 20.6398 23.3628 20.6398 26.716C20.6398 30.0691 23.358 32.7874 26.7112 32.7874Z"
      stroke="#EBD457"
      strokeWidth={2.64935}
      strokeLinecap="square"
      strokeLinejoin="bevel"
    />
    <path
      d="M20.6398 7.28667V3.64382C20.6398 2.99972 20.8956 2.382 21.3511 1.92656C21.8065 1.47111 22.4242 1.21525 23.0683 1.21525H25.9341C26.5056 1.20485 27.0626 1.39643 27.5068 1.75626C27.951 2.11609 28.2541 2.6211 28.3626 3.18239L32.7098 25.501"
      stroke="#EBD457"
      strokeWidth={2.64935}
      strokeLinecap="square"
      strokeLinejoin="bevel"
    />
    <path
      d="M20.6821 19.127V26.2103"
      stroke="#EBD457"
      strokeWidth={2.64935}
      strokeLinecap="square"
      strokeLinejoin="bevel"
    />
  </svg>
);
const Memo = memo(SvgIcoBinocular);
export default Memo;
