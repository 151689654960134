import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface MapState {
  initialAnimationFinished: boolean;
  pinLocation?: google.maps.LatLngLiteral;
}

const initialState: MapState = {
  initialAnimationFinished: false,
};

const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    FINISH_INITIAL_ANIMATION: (state) => {
      state.initialAnimationFinished = true;
    },
    SET_PIN_LOCATION: (
      state,
      { payload }: PayloadAction<MapState['pinLocation']>,
    ) => {
      state.pinLocation = payload;
    },
  },
});

export const { actions: mapActions } = mapSlice;

export default mapSlice.reducer;
