import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoCheckOverflow = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 38 34" fill="none" {...props}>
    <rect y={2} width={32} height={32} fill="black" />
    <path d="M7 11.8621L17.6842 24L36 2" stroke="#EBD457" strokeWidth={5} />
  </svg>
);
const Memo = memo(SvgIcoCheckOverflow);
export default Memo;
