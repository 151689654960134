import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoLockCircleGrey = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
    <circle cx={10} cy={10} r={9} fill="black" stroke="black" strokeWidth={2} />
    <path
      d="M13.9686 15.4578H6.03143V9.50498H13.9686V15.4578ZM8.01572 9.50498V7.52069C8.01572 6.42537 8.90567 5.53641 10 5.53641C11.0943 5.53641 11.9843 6.42537 11.9843 7.52069V9.50498H12.9764V7.52069C12.9764 5.87771 11.644 4.54427 10 4.54427C8.35602 4.54427 7.02357 5.87771 7.02357 7.52069V9.50498H8.01572Z"
      fill="#8A8A8A"
    />
  </svg>
);
const Memo = memo(SvgIcoLockCircleGrey);
export default Memo;
