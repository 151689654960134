import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoX = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 44 43" fill="none" {...props}>
    <path
      d="M22.0659 30.0998L9.18601 43H17.7722L22.0659 38.6995L26.3596 43H34.9458L22.0659 30.0998ZM13.4785 21.5012L0.598633 8.60094V17.2007L4.89233 21.5012L0.598633 25.8016V34.4014L13.4785 21.5012ZM22.0647 12.9014L34.9458 0H26.3596L22.0659 4.30047L17.7722 0H9.18601L22.0659 12.9002L22.0647 12.9014ZM43.5308 25.8016L39.2371 21.5012L43.5308 17.2007V8.59977L30.6509 21.5L43.5308 34.4002V25.8005V25.8016Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgIcoX);
export default Memo;
