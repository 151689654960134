import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoStar = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 14 14" fill="none" {...props}>
    <circle cx={7} cy={7} r={7} fill="currentColor" />
    <path
      d="M6.99972 3.30469L7.8882 6.03879H10.7631L8.43703 7.72887L9.3255 10.463L6.99972 8.77321L4.67363 10.463L5.56211 7.72887L3.23633 6.03879H6.11125L6.99972 3.30469Z"
      fill="black"
    />
  </svg>
);
const Memo = memo(SvgIcoStar);
export default Memo;
