import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getAuthLoading,
  getUserId,
  isStoredTokenValid,
} from 'store/auth/auth.selectors';
import { authActions } from 'store/auth/auth.slice';
import { ContentRoute } from 'store/navigation/navigation.slice';

export const useAuth = () => {
  const dispatch = useDispatch();

  const isLoading = useSelector(getAuthLoading);
  const userId = useSelector(getUserId);
  const isLoggedIn = useSelector(isStoredTokenValid);

  const login = useCallback(
    (route?: ContentRoute) => {
      dispatch(authActions.LOGIN_START(route));
    },
    [dispatch],
  );

  return { isLoggedIn, login, userId, isLoading };
};
