import { Components, Theme } from '@mui/material';

export const MuiLink: Components<Theme>['MuiLink'] = {
  defaultProps: {
    underline: 'always',
    color: 'brand01.100',
  },
  styleOverrides: {
    root: ({ theme }) => ({
      position: 'relative',
      fontSize: '12px',
      overflow: 'hidden',
      cursor: 'pointer',
      textDecorationColor: 'currentColor',
      '&:hover': {
        color: theme.palette.brand01[100],
        textShadow: `0px 0px 12px rgba(235, 212, 87, 0.5)`,
      },
      '&:disabled': {
        opacity: 0.2,
        '&:hover': {
          color: 'currentColor',
        },
      },
    }),
  },
};
