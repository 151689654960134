import * as Sentry from '@sentry/react';
import axios from 'axios';
import _get from 'lodash/get';
import qs from 'query-string';
import { Store } from 'redux';

import config from 'config';

import { isTokenValid } from 'utils/token.utils';
import { inIframe } from 'utils/window.utils';

import { apiService } from 'services';
import {
  getAuthLoading,
  getRedirectRoute,
  isStoredTokenValid,
} from 'store/auth/auth.selectors';
import { authActions } from 'store/auth/auth.slice';
import { navigationActions } from 'store/navigation/navigation.slice';

export const checkForLogin = async (store: Store) => {
  const state = store.getState();

  const isValid = isStoredTokenValid(state);

  if (isValid) {
    store.dispatch(authActions.LOGIN_SUCCESS());
    return;
  }

  store.dispatch(authActions.RESET());

  const { code } = qs.parse(window.location.search);

  if (code && typeof code === 'string') {
    // Clear the query
    window.history.pushState({}, document.title, window.location.pathname);

    const redirectUri = inIframe()
      ? config.IFRAME_CLIENT_URL
      : config.CLIENT_URL;

    try {
      const { data: authData } = await apiService.getAuth(code, redirectUri);

      const isValidToken = isTokenValid(authData.accessToken);

      if (isValidToken) {
        const redirectRoute = getRedirectRoute(state);

        store.dispatch(authActions.SET_ACCESS_TOKEN(authData.accessToken));
        store.dispatch(authActions.LOGIN_SUCCESS());

        if (redirectRoute) {
          store.dispatch(navigationActions.CONTENT_NAVIGATE_TO(redirectRoute));
        }
      } else {
        throw new Error('Token not valid');
      }
    } catch (e) {
      store.dispatch(authActions.LOGIN_ERROR());
      store.dispatch(authActions.RESET());

      if (axios.isAxiosError(e)) {
        const errorCode = _get(e, 'response.data.errors[0].code');
        Sentry.withScope((scope) => {
          scope.setExtras({
            name: errorCode ?? 'Login error',
            error: e,
          });
          Sentry.captureMessage(errorCode ?? 'Login error');
        });

        store.dispatch(
          navigationActions.MODAL_OPEN({
            key: 'login-error',
            params: { code: errorCode ?? 'Login error' },
          }),
        );
      } else {
        Sentry.withScope((scope) => {
          scope.setExtras({
            name: 'login-error',
            error: e,
          });
          Sentry.captureMessage('Login error');
        });

        store.dispatch(
          navigationActions.MODAL_OPEN({
            key: 'login-error',
            params: { code: 'Login error' },
          }),
        );
      }
    }
  } else {
    const isLoggingIn = getAuthLoading(state);
    if (isLoggingIn) {
      store.dispatch(authActions.LOGIN_ERROR());
    }
  }
};
