import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { useAuth } from 'hooks';
import { QueryKeys } from 'queries/QueryKeys';
import { apiService } from 'services';
import {
  Coordinate,
  MiniGames,
  PostGuessGameAnswerParams,
  PostGuessGameAnswerResponse,
} from 'types/game.types';

const postGuess = async (params: PostGuessGameAnswerParams) => {
  const { data } = await apiService.postGuessGameAnswer(params);

  return data;
};

export const usePostGuessGame = (episodeId = '') => {
  const { userId = '' } = useAuth();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation<
    PostGuessGameAnswerResponse,
    AxiosError,
    { id: PostGuessGameAnswerParams['coordinateId']; position: Coordinate }
  >(
    ({ id, position }) =>
      postGuess({ userId, episodeId, coordinateId: id, coordinates: position }),
    {
      onSuccess: (data, { id, position }) => {
        const miniGames = queryClient.getQueryData<MiniGames>(
          QueryKeys.miniGames.byEpisode(episodeId),
        );

        if (miniGames) {
          queryClient.setQueryData<MiniGames | undefined>(
            QueryKeys.miniGames.byEpisode(episodeId),
            {
              ...miniGames,
              guessGame:
                miniGames?.guessGame.map((m) => {
                  if (m.id === id) {
                    return {
                      ...m,
                      userAnswer: {
                        isCorrect: data.isCorrect,
                        distanceInMeters: data.distanceInMeters,
                        coordinate: position,
                      },
                    };
                  }

                  return m;
                }) ?? [],
            },
          );
        }
      },
    },
  );

  return { mutate, isLoading };
};
