import { FC, ReactNode } from 'react';
import { RawIntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';

import { getLocale } from 'store/intl/intl.selectors';

import { DEFAULT_LOCALE, intl, locales } from './config';

interface Props {
  children?: ReactNode;
}

const IntlSelectorProvider: FC<Props> = ({ children }) => {
  const locale = useSelector(getLocale);
  const chosenLocale = locales.includes(locale) ? locale : DEFAULT_LOCALE;

  return (
    <RawIntlProvider key={chosenLocale} value={intl[chosenLocale]}>
      {children}
    </RawIntlProvider>
  );
};

export default IntlSelectorProvider;
