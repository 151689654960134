import 'utils/polyfill.utils';

import { createRoot } from 'react-dom/client';

import { runInDev, runNotInDev } from 'utils/env.utils';

import { initSentry } from 'services/sentry';

import App from './App';

runNotInDev(() => {
  initSentry();
});

runInDev(() => {
  // Enable to start mocking!
  // const { worker } = require('./mocks/browser');
  // worker.start({ onUnhandledRequest: 'bypass' });
});

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(<App />);
