import headers from './request/headers.interceptor';
import auth from './response/auth.interceptor';

// Request interceptors
const requestInterceptors = [headers];

// Response interceptors
const responseInterceptors = [auth];

export { requestInterceptors, responseInterceptors };
