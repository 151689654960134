import { Box, BoxProps } from '@mui/material';
import { motion } from 'framer-motion';
import { ReactNode } from 'react';

import { IcoCorner } from 'components/@icons/custom';

interface Props extends Omit<BoxProps, 'overflow'> {
  children?: ReactNode;
  disablePadding?: boolean;
  cornerOpacity?: number;
  size?: 'small' | 'large';
  overflow?: boolean;
}

const CornerItem = ({
  children,
  disablePadding = true,
  cornerOpacity = 1,
  overflow,
  size = 'large',
  ...rest
}: Props) => {
  const cornerSize = size === 'large' ? 18 : 11;
  return (
    <Box
      position="relative"
      display="flex"
      justifyContent="center"
      alignItems="center"
      overflow="visible"
      {...rest}
    >
      <Box
        position="absolute"
        top={overflow ? -cornerSize : size === 'large' ? -2 : -9}
        left={overflow ? -cornerSize : 0}
        sx={{ opacity: cornerOpacity }}
      >
        <motion.div variants={{ hover: { scale: 0.9 } }}>
          <IcoCorner fontSize={cornerSize} />
        </motion.div>
      </Box>
      <Box m={disablePadding ? 0 : size === 'large' ? 1 : 0.7} flex={1}>
        {children}
      </Box>
      <Box
        position="absolute"
        bottom={overflow ? -cornerSize : size === 'large' ? -2 : -9}
        right={overflow ? -cornerSize : 0}
        sx={{ transform: 'scale(-1)', opacity: cornerOpacity }}
      >
        <motion.div variants={{ hover: { scale: 0.9 } }}>
          <IcoCorner fontSize={cornerSize} />
        </motion.div>
      </Box>
    </Box>
  );
};

export default CornerItem;
