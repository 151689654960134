import { Box, Stack, Typography } from '@mui/material';

import { BrandButton, FadeImage } from 'components/@common';

interface Props {
  url: string;
  image?: string;
  rewatch?: boolean;
  title: string;
  subtitle?: string;
  duration?: number;
}

const VtmGoCard = ({
  title,
  subtitle,
  duration,
  image,
  rewatch,
  url,
}: Props) => {
  let body = subtitle ?? '';
  if (duration) {
    if (subtitle) {
      body += ' - ';
    }
    body += `${Math.round(duration / 60)} min`;
  }

  return (
    <Stack
      position="relative"
      p={2}
      justifyContent="space-between"
      width="100%"
      height="100%"
    >
      {image && (
        <Box
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          zIndex={-1}
        >
          <FadeImage src={image} width="100%" height="100%" />
          <Box
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            zIndex={2}
            sx={{
              background:
                'linear-gradient(180deg, rgba(0, 0, 0, 0.5) 21.88%, rgba(0, 0, 0, 0.82) 70.71%, #000000 100%)',
            }}
          />
        </Box>
      )}
      <Stack gap={0.5}>
        <Typography variant="h4" component="p">
          {title}
        </Typography>
        {!!body.length && (
          <Typography variant="h6Secondary" color="neutral01.500" component="p">
            {body}
          </Typography>
        )}
      </Stack>
      <BrandButton href={url} rewatch={rewatch} />
    </Stack>
  );
};

export default VtmGoCard;
