import { isDemo } from 'utils/env.utils';

import { SixPlayButton } from '../SixPlayButton';
import { VtmGoButton } from '../VtmGoButton';

interface Props {
  onClick?: () => void;
  href: string;
  rewatch?: boolean;
}

const BrandButton = (props: Props) => {
  if (isDemo()) {
    return <SixPlayButton {...props} />;
  } else {
    return <VtmGoButton {...props} />;
  }
};

export default BrandButton;
