import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoCheckboxDefault = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
    <rect x={0.5} y={0.5} width={19} height={19} stroke="white" />
  </svg>
);
const Memo = memo(SvgIcoCheckboxDefault);
export default Memo;
