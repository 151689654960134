import { useQuery } from '@tanstack/react-query';

import { CacheTime, StaleTime } from 'queries/constants';
import { QueryKeys } from 'queries/QueryKeys';
import { apiService } from 'services';

const getHeatmap = async (episodeId: string) => {
  const { data } = await apiService.getHeatmap(episodeId);

  return data;
};

export const useHeatmap = (episodeId = '', enabled = false) => {
  const { data, isLoading } = useQuery(
    QueryKeys.heatmap.byEpisode(episodeId),
    () => getHeatmap(episodeId),
    {
      enabled: !!episodeId && enabled,
      cacheTime: CacheTime.FOREVER,
      staleTime: StaleTime.MINUTE,
    },
  );

  return {
    data,
    isLoading,
  };
};
