import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useState,
} from 'react';

import { MapZoomProvider } from './MapZoomProvider';

type Context<S> = [S, Dispatch<SetStateAction<S>>];

export const MapContext = createContext<Context<google.maps.Map | undefined>>([
  undefined,
  () => {},
]);

interface Props {
  children?: ReactNode;
}

export const MapProvider = ({ children }: Props) => {
  const state = useState<google.maps.Map | undefined>();

  return (
    <MapContext.Provider value={state}>
      <MapZoomProvider>{children}</MapZoomProvider>
    </MapContext.Provider>
  );
};
