export const isProd = () => process.env.NODE_ENV === 'production';
export const isDev = () => process.env.NODE_ENV === 'development';
export const isTest = () => process.env.REACT_APP_BUILD_ENV === 'test';
export const isStag = () => process.env.REACT_APP_BUILD_ENV === 'staging';
export const isDemo = () => process.env.REACT_APP_BUILD_ENV === 'demo';
export const runInDev = (func: () => void) => (isDev() ? func() : null);
export const runNotInDev = (func: () => void) => (!isDev() ? func() : null);
export const runInTest = (func: () => void) => (isTest() ? func() : null);
export const runNotInTest = (func: () => void) => (!isTest() ? func() : null);
export const runInProd = (func: () => void) => (isProd() ? func() : null);
