import { Box } from '@mui/material';
import {
  Children,
  cloneElement,
  isValidElement,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
} from 'react';

import { usePrevious } from 'hooks';

import { useMap } from '../MapProvider/useMap';
import { useDeepCompareEffectForMaps } from './useDeepCompareEffectForMaps';

interface MapProps extends google.maps.MapOptions {
  className?: string;
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onIdle?: (map: google.maps.Map) => void;
  onInit?: (map: google.maps.Map) => void;
  children?: ReactNode;
  mapId?: string;
  mapKey: string;
}

const Map = ({
  className,
  onClick,
  onIdle,
  onInit,
  children,
  backgroundColor,
  mapKey,
  mapId,
  ...options
}: MapProps) => {
  const finalOptions = useMemo(() => ({ ...options, mapId }), [mapId, options]);
  const prevMapKey = usePrevious(mapKey);
  const ref = useRef<HTMLDivElement>(null);
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const [map, setMap] = useMap();

  useEffect(() => {
    if (ref.current && map === undefined) {
      const googleMap = new window.google.maps.Map(ref.current, {
        mapId,
        backgroundColor,
        gestureHandling: 'greedy',
      });

      setMap(googleMap);

      onInit?.(googleMap);
    }
  }, [ref, map, setMap, backgroundColor, onInit, mapId]);

  useEffect(() => {
    if (
      map &&
      ref.current &&
      mapContainerRef.current &&
      mapKey !== prevMapKey
    ) {
      const div = map.getDiv();
      div.remove();
      mapContainerRef.current.append(div);
      map.setOptions(finalOptions);
    }
  }, [map, finalOptions, mapKey, prevMapKey]);

  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(finalOptions);
    }
  }, [map, finalOptions]);

  useEffect(() => {
    if (map) {
      ['click', 'idle'].forEach((eventName) =>
        google.maps.event.clearListeners(map, eventName),
      );

      if (onClick) {
        map.addListener('click', onClick);
      }

      if (onIdle) {
        map.addListener('idle', () => onIdle(map));
      }
    }
    return () => {
      if (map) {
        ['click', 'idle'].forEach((eventName) =>
          google.maps.event.clearListeners(map, eventName),
        );
      }
    };
  }, [map, onClick, onIdle]);

  return (
    <>
      <Box
        width="100%"
        height="100%"
        position="relative"
        overflow="hidden"
        ref={mapContainerRef}
        id="map-container"
      >
        <Box
          ref={ref}
          className={className}
          position="absolute"
          top={0}
          bottom={0}
          left={-5}
          right={-5}
          id={`map-content-${mapKey}`}
        />
      </Box>

      {Children.map(children, (child) => {
        if (isValidElement(child)) {
          // @ts-ignore https://developers.google.com/maps/documentation/javascript/react-map
          return cloneElement(child, { map });
        }
      })}
    </>
  );
};

export default Map;
