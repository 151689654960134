import { Box, styled } from '@mui/material';
import { BoxProps } from '@mui/system';

interface Props extends BoxProps {
  videoId: string;
  autoPlay?: boolean;
}

const VideoFrame = styled('iframe')(() => ({
  border: 0,
  position: 'absolute',
  left: 0,
  top: 0,
  overflow: 'hidden',
}));

const VtmVideo = ({ videoId, autoPlay, ...rest }: Props) => {
  return (
    <Box {...rest}>
      <Box
        pt="56.25%"
        position="relative"
        height={0}
        width="100%"
        bgcolor="neutral01.200"
      >
        <VideoFrame
          title={`video-${videoId}`}
          allowFullScreen
          height="100%"
          width="100%"
          src={`https://mychannels.video/embed/${videoId}?automute=true&autoplay=${autoPlay}&pip=false&single_play=false`}
        />
      </Box>
    </Box>
  );
};
export default VtmVideo;
