import { startAppListening } from 'services/redux/listenerMiddleware';
import { mapActions } from 'store/map/map.slice';

import { navigationActions } from './navigation.slice';

export default function startNavigationListeners() {
  startAppListening({
    predicate: (action) =>
      action.type === navigationActions.START_PIN_PLACEMENT.type,
    effect: (_, listenerApi) => {
      listenerApi.cancelActiveListeners();
      listenerApi.dispatch(mapActions.SET_PIN_LOCATION(undefined));
    },
  });
}
