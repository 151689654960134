import {
  FormControlLabel,
  formControlLabelClasses,
  FormControlLabelProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';

interface Props extends FormControlLabelProps {
  correct?: boolean;
  wrong?: boolean;
}

export const FormControlLabelStyled = styled(FormControlLabel, {
  shouldForwardProp: (prop) => prop !== 'wrong' && prop !== 'correct',
})<Props>(({ theme, checked, wrong, correct }) => ({
  backgroundColor: theme.palette.neutral01[100],
  width: '100%',
  padding: theme.spacing(2),
  marginBottom: theme.spacing(1),
  marginRight: 0,
  marginLeft: 0,

  ...(checked &&
    !wrong &&
    !correct && {
      outline: `1px solid ${theme.palette.primary.main}`,
      outlineOffset: -1,
    }),

  ...(wrong && {
    outline: `1px solid ${theme.palette.error.main}`,
    outlineOffset: -1,
  }),

  ...(correct && {
    outline: `1px solid ${theme.palette.success.main}`,
    outlineOffset: -1,
  }),

  '&:hover': {
    boxShadow: '0px 0px 12px rgba(235, 212, 87, 0.5)',
    '@media (hover: none)': {
      boxShadow: 'none',
    },

    [`&.${formControlLabelClasses.disabled}`]: {
      boxShadow: 'none',
    },
  },

  [`& .${formControlLabelClasses.label}.${formControlLabelClasses.disabled}`]: {
    color: theme.palette.neutral02[100],
  },
}));
