import { Status, Wrapper } from '@googlemaps/react-wrapper';
import { Box } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';

import config from 'config';

interface Props {
  position: google.maps.LatLngLiteral;
  pov?: Partial<google.maps.StreetViewPov>;
  onClick?: () => void;
}

const StreetView = ({ position, pov, onClick }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [panorama, setPanorama] = useState<google.maps.StreetViewPanorama>();

  const initPanorama = useCallback(() => {
    if (ref.current && !panorama) {
      const mapPanorama = new google.maps.StreetViewPanorama(ref.current, {
        position,
        pov: pov
          ? { heading: pov.heading ?? 0, pitch: pov.pitch ?? 0 }
          : undefined,
        linksControl: false,
        showRoadLabels: false,
        fullscreenControl: false,
        visible: true,
        addressControl: false,
        motionTracking: false,
      });
      setPanorama(mapPanorama);
    }
  }, [ref, panorama, position, pov]);

  useEffect(() => {
    if (ref.current && panorama) {
      panorama.setOptions({
        position,
        pov: pov
          ? { heading: pov.heading ?? 0, pitch: pov.pitch ?? 0 }
          : undefined,
      });
    }
  }, [panorama, position, pov]);

  return (
    <>
      <Wrapper
        libraries={['visualization', 'geometry']}
        apiKey={config.GOOGLE_MAPS_API_KEY}
        language="nl-BE"
        region="BE"
        version="weekly"
        callback={(status) => {
          if (status === Status.SUCCESS) {
            initPanorama();
          }
        }}
      />
      <Box
        bgcolor="neutral01.100"
        ref={ref}
        position="absolute"
        top={0}
        left={0}
        bottom={0}
        right={0}
        onPointerDown={() => onClick?.()}
      />
    </>
  );
};

export default StreetView;
