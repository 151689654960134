import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoBack = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 7 14" fill="none" {...props}>
    <path
      d="M7 0V4.66667L4.66667 7L7 9.33333V14L-5.21541e-08 7L7 0Z"
      fill="currentColor"
    />
  </svg>
);

const Memo = memo(SvgIcoBack);
export default Memo;
