import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useMiniGames } from 'queries';
import { getContentRoute } from 'store/navigation/navigation.selectors';

import { Question, Results } from 'components/@quiz';
import LoadingState from 'components/@states/LoadingState';

const QuizContent = () => {
  const route = useSelector(getContentRoute);
  const [lastAnswered, setLastAnswered] = useState(false);
  const episodeId = route.params?.episodeId ?? '';

  const { data, isLoading } = useMiniGames(episodeId);

  const isFinished = data?.quiz.every((q) => q.userAnswer);

  useEffect(() => {
    if (isFinished) {
      setLastAnswered(true);
    }
  }, [isFinished]);

  if (isLoading) return <LoadingState />;

  return (
    <>
      {data?.quiz && (
        <>
          {isFinished && lastAnswered ? (
            <Results quiz={data.quiz} />
          ) : (
            <Question
              quiz={data.quiz}
              onLastAnswered={() => setLastAnswered(true)}
            />
          )}
        </>
      )}
    </>
  );
};

export default QuizContent;
