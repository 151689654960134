import { useMutation, useQueryClient } from '@tanstack/react-query';

import { QueryKeys } from 'queries/QueryKeys';
import { apiService } from 'services';
import { PostQuizAnswerParams } from 'types/game.types';

const postQuizAnswer = async (variables: PostQuizAnswerParams) => {
  const { data } = await apiService.postQuizAnswer(variables);

  return data;
};

export const usePostQuizAnswer = () => {
  const queryClient = useQueryClient();
  const { mutate, isLoading, data, reset } = useMutation(postQuizAnswer, {
    onSuccess: (_data, { episodeId }) => {
      queryClient.invalidateQueries(QueryKeys.miniGames.byEpisode(episodeId));
    },
  });

  return {
    data,
    answerQuestion: mutate,
    isLoading,
    reset,
  };
};
