import { AxiosRequestConfig } from 'axios';

import store from 'store';
import { getAccessToken } from 'store/auth/auth.selectors';
import { getLocale } from 'store/intl/intl.selectors';

const success = (connection: AxiosRequestConfig) => {
  const state = store.store.getState();
  const token = getAccessToken(state);

  if (!connection.headers) {
    connection.headers = {};
  }

  if (connection.headers) {
    if (token && connection.authEnabled) {
      connection.headers.Authorization = `Bearer ${token}`;
    }

    const locale = getLocale(state);
    connection.headers['Accept-Language'] = locale.toUpperCase();
  }
  return connection;
};

const error = (connection: AxiosRequestConfig) => Promise.reject(connection);

export default { success, error };
