import { Components } from '@mui/material';

import EurostileExtendedWoff from 'assets/fonts/eurostile_extended_black.woff';
import EurostileExtendedWoff2 from 'assets/fonts/eurostile_extended_black.woff2';

export const MuiCssBaseline: Components['MuiCssBaseline'] = {
  styleOverrides: `
    body {
      background-color: #000;
      -webkit-tap-highlight-color: transparent;
    }

    @font-face {
      font-family: 'Eurostile Extended';
      font-style: normal;
      font-weight: 700;
      font-display: swap;
      src: local('Eurostile Extended'),
           url(${EurostileExtendedWoff2}) format('woff2'),
           url(${EurostileExtendedWoff}) format('woff');
    }

    * > ::-webkit-scrollbar {
      background-color: #000;
      width: 5px;
    }
    
    * > ::-webkit-scrollbar-thumb {
      background-color: rgba(255, 255, 255, 0.1);
    }
  `,
};
