import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { useAuth } from 'hooks';
import { QueryKeys, useEpisodes } from 'queries';
import { StaleTime } from 'queries/constants';
import { apiService } from 'services';

const getUserLeaderboard = async (
  userId: string,
  previousEpisode: boolean,
  reveal?: string,
) => {
  const { data: response } = await apiService.getUserLeaderboard(
    userId,
    previousEpisode,
    reveal,
  );
  return response;
};

export const useUserLeaderboard = (previousEpisode: boolean) => {
  const { currentEpisode } = useEpisodes();

  const { userId = '' } = useAuth();
  const { data, status, isLoading, error, isFetching } = useQuery(
    QueryKeys.leaderboard.user(previousEpisode),

    () =>
      getUserLeaderboard(
        userId,
        previousEpisode,
        // We pass the episode id when it's revealed. So we know 100% sure the call isn't cached
        currentEpisode?.isRevealed ? currentEpisode.id : undefined,
      ),
    {
      enabled: !!userId,
      cacheTime: StaleTime.FOREVER,
      staleTime: StaleTime.MINUTE,
    },
  );

  const user = useMemo(
    () => data?.find((u) => u.id === userId),
    [data, userId],
  );

  return {
    data,
    user,
    status,
    isLoading,
    isFetching,
    error,
  };
};
