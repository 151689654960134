import { Box, styled } from '@mui/material';
import { useEffect, useState } from 'react';

import config from 'config';

import shareOverlay from 'assets/png/img-share-guess-overlay.png';

import { ShareRenderer } from '../ShareRenderer';

const CENTER_OFFSET = 216;
const ZOOM = 10;

const MapImg = styled('img')({
  position: 'absolute',
  top: CENTER_OFFSET,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: '#000',
  objectFit: 'fill',
  objectPosition: 'center bottom',
});

interface Props {
  position: { lat: number; lng: number };
  onComplete: (image: string) => void;
}

const getImage = async (url: string): Promise<string> => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data as string);
    };
  });
};

const ShareGuess = ({ position, onComplete }: Props) => {
  const [googleImage, setGoogleImage] = useState<string>();

  useEffect(() => {
    async function loadImage() {
      let url = `https://maps.googleapis.com/maps/api/staticmap?size=360x${Math.round(
        640 - CENTER_OFFSET / 3,
      )}&scale=2&zoom=${ZOOM}&center=${position.lat},${position.lng}&key=${
        config.GOOGLE_MAPS_API_KEY
      }&map_id=${config.GOOGLE_MAPS_IDS.dark}`;

      const image = await getImage(url);
      setGoogleImage(image);
    }
    loadImage();
  }, [position.lat, position.lng]);

  return (
    <ShareRenderer
      id={`guess-${position.lat}-${position.lng}`}
      onComplete={onComplete}
      render={!!googleImage}
    >
      <Box
        width={1080}
        height={1920}
        position="relative"
        overflow="hidden"
        bgcolor="#000"
      >
        {googleImage && (
          <MapImg
            id={`img-guess-${position.lat}-${position.lng}`}
            width={1080}
            height={1920 - CENTER_OFFSET}
            src={googleImage}
          />
        )}
        <Box
          component="img"
          position="absolute"
          bottom={0}
          left={0}
          right={0}
          top={0}
          width="100%"
          height="100%"
          src={shareOverlay}
        />
      </Box>
    </ShareRenderer>
  );
};

export default ShareGuess;
