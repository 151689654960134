import { isProd } from 'utils/env.utils';

export interface ENV_CONFIG {
  NAME: string;
  VERSION: string;
  BUILD_ENV: string;
  API_BASE: string;
  CLIENT_URL: string;
  IFRAME_CLIENT_URL: string;
  ANALYTICS_GOOGLE_TRACKING_ID?: string;
  PORT?: number;
  ENABLE_DEV_TOOLS: boolean;
  LOCAL_STORAGE_ID: string;
  DEFAULT_CURRENCY: string;
  DEFAULT_LOCALE: string;
  DEFAULT_LOCALE_DATE: string;
  VTM_CLIENT_ID: string;
  GOOGLE_MAPS_API_KEY: string;
  GOOGLE_MAPS_IDS: {
    dark: string;
  };
  ANALYTICS: {
    GTM_ID: string;
  };
  SENTRY: {
    dsn: string;
    environment?: string;
    release?: string;
  };
}

const config: ENV_CONFIG = {
  // general
  NAME: process.env.REACT_APP_NAME || '',
  VERSION: process.env.REACT_APP_VERSION || '',
  BUILD_ENV: process.env.REACT_APP_BUILD_ENV || '',

  // api
  API_BASE: process.env.REACT_APP_API_BASE || '',
  CLIENT_URL: process.env.REACT_APP_CLIENT_URL || '',
  IFRAME_CLIENT_URL: process.env.REACT_APP_IFRAME_CLIENT_URL || '',

  // analytics
  ANALYTICS_GOOGLE_TRACKING_ID:
    process.env.REACT_APP_ANALYTICS_GOOGLE_TRACKING_ID || '',

  // error tracking
  SENTRY: {
    dsn: 'https://4b46cd28e52045de99d8cacecad5d255@o190235.ingest.sentry.io/4504248382652416',
    environment: process.env.REACT_APP_BUILD_ENV,
    release: isProd()
      ? `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`
      : `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}-${process.env.REACT_APP_BUILD_ENV}`,
  },

  VTM_CLIENT_ID: 'vtm-bx-web',

  // analytics
  ANALYTICS: {
    GTM_ID: process.env.REACT_APP_TAG_MANAGER_ID ?? '',
  },

  GOOGLE_MAPS_API_KEY: 'AIzaSyD2X2jgbiRqd0PGqOZcx3mQRwdgd2RA7yE',
  GOOGLE_MAPS_IDS: {
    dark: '399b9bc3fd47858b',
  },
  // dev
  PORT: Number(process.env.REACT_APP_PORT ?? 3000),
  ENABLE_DEV_TOOLS: Boolean(process.env.REACT_APP_ENABLE_DEV_TOOLS ?? true),
  LOCAL_STORAGE_ID: process.env.REACT_APP_LOCAL_STORAGE_ID ?? '',
  DEFAULT_CURRENCY: process.env.REACT_APP_DEFAULT_CURRENCY ?? 'EUR',
  DEFAULT_LOCALE: process.env.REACT_APP_DEFAULT_LOCALE ?? 'en-GB',
  DEFAULT_LOCALE_DATE: process.env.REACT_APP_DEFAULT_LOCALE_DATE ?? 'nl',
};

export default config;
