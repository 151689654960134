import { Link, Typography } from '@mui/material';
import { PrismicRichText } from '@prismicio/react';
import { RichTextField } from '@prismicio/types';

import { FadeImage } from '../FadeImage';

interface Props {
  text?: RichTextField;
}

const PrismicText = ({ text }: Props) => {
  return (
    <Typography component="div">
      <PrismicRichText
        field={text}
        components={{
          hyperlink: ({ children, node }) => (
            <Link target="_blank" href={node.data.url} color="neutral02.100">
              <Typography variant="paragraph1">{children}</Typography>
            </Link>
          ),
          image: ({ node }) => (
            <FadeImage
              src={node.url}
              width="100%"
              height="auto"
              alt={node.alt ?? undefined}
              sx={{
                my: 3,
                aspectRatio: `${
                  node.dimensions.width / node.dimensions.height
                }`,
              }}
            />
          ),
          heading1: ({ children }) => (
            <Typography variant="h5" component="h1" mt={3} mb={1}>
              {children}
            </Typography>
          ),
          heading2: ({ children }) => (
            <Typography variant="h6" component="h2" mt={3} mb={1}>
              {children}
            </Typography>
          ),
          paragraph: ({ children }) => (
            <Typography variant="paragraph1">{children}</Typography>
          ),
        }}
      />
    </Typography>
  );
};
export default PrismicText;
