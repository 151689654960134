import React from 'react';

import { createRoute } from 'utils/router.utils';

import { Routes } from 'pages/routes.constants';

/**
 * use lazy imports to get code splitting out-of-the-box
 * you can use "webpackChunkName" to name this chunk
 */
// tslint:disable:space-in-parens
const componentPromise = import(
  './GuessGame' /* webpackChunkName: "guess-game" */
);

const Component = React.lazy(() => componentPromise);
// tslint:enable

export default createRoute(Component, {
  path: Routes.GuessGame,
});
