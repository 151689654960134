import { Box, Stack, Typography } from '@mui/material';
import { motion, Transition, Variants } from 'framer-motion';
import { findIndex } from 'lodash';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { useAnalytics } from 'hooks';
import { usePostQuizAnswer } from 'queries';
import { getUserId } from 'store/auth/auth.selectors';
import { getContentRoute } from 'store/navigation/navigation.selectors';
import { EventName } from 'types/analytics.types';
import { Answer, MiniGames } from 'types/game.types';

import { AnimatedButton, PrismicText, StepCounter } from 'components/@common';

import { AnswerList } from '../AnswerList';

interface Props {
  quiz: MiniGames['quiz'];
  onLastAnswered(): void;
}

type SelectedAnswers = Record<string, Answer | null>;

const animationVariant: Variants = {
  initial: { opacity: 0, y: 20 },
  animate: {
    opacity: 1,
    y: 0,
  },
};

const transition = (index: number): Transition => {
  return { delay: 0.5 + index * 0.3, duration: 0.7, ease: 'easeOut' };
};

const Question = ({ quiz, onLastAnswered }: Props) => {
  const { trackEvent } = useAnalytics();
  const [initialLoaded, setInitialLoaded] = useState(false);
  const [question, setQuestion] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState<SelectedAnswers>({});
  const [answered, setAnswered] = useState(false);
  const currentQuestion = quiz[question];
  const route = useSelector(getContentRoute);
  const userId = useSelector(getUserId);

  useEffect(() => {
    if (!!quiz.length && !initialLoaded) {
      setInitialLoaded(true);
      setQuestion(findIndex(quiz, (q) => !q.userAnswer));
    }
  }, [initialLoaded, quiz]);

  const handleSelect = (answer: Answer) => {
    if (currentQuestion) {
      const isSelected = selectedAnswers[currentQuestion.id]?.id === answer.id;

      setSelectedAnswers((state) => ({
        ...state,
        [currentQuestion.id]: isSelected ? null : answer,
      }));
    }
  };

  const {
    answerQuestion,
    isLoading: isSubmitting,
    data: answer,
    reset,
  } = usePostQuizAnswer();

  const handleSubmitAnswer = () => {
    if (currentSelected && userId && route.params) {
      answerQuestion(
        {
          questionId: currentQuestion.id,
          answerId: currentSelected.id,
          episodeId: route.params.episodeId,
          userId,
        },
        {
          onSuccess: () => {
            setAnswered(true);
          },
        },
      );
      trackEvent({
        eventName: EventName.QuizAnswer.replace('{count}', `${question + 1}`),
      });
    }
  };

  const handleSubmit = () => {
    if (answered) {
      setAnswered(false);
      setQuestion((state) => {
        const next = state + 1;
        if (next === quiz.length - 1) {
          onLastAnswered();
        }

        return next;
      });
      reset();
    } else {
      handleSubmitAnswer();
    }
  };

  if (!quiz.length) return null;

  const currentSelected = selectedAnswers[currentQuestion.id];

  return (
    <motion.div key={currentQuestion.id} initial="initial" animate="animate">
      <Stack flex={1} pt={{ xs: 5, md: 0 }} justifyContent="space-between">
        <Box>
          <motion.div variants={animationVariant} transition={transition(0)}>
            <Box
              display="flex"
              justifyContent="space-between"
              mb={{ xs: 3, md: 6 }}
            >
              <Typography variant="h2">
                <FormattedMessage id="games.modal.quiz.title" />
              </Typography>
              <StepCounter step={question + 1} totalSteps={quiz.length} />
            </Box>
          </motion.div>
          <motion.div variants={animationVariant} transition={transition(1)}>
            <Typography variant="h4" color="primary.main">
              <FormattedMessage
                id="quiz.current.title"
                values={{ current: question + 1 }}
              />
            </Typography>
          </motion.div>
          <motion.div variants={animationVariant} transition={transition(2)}>
            <PrismicText text={currentQuestion.question} />
          </motion.div>
          <motion.div variants={animationVariant} transition={transition(3)}>
            <Box flex={1} mt={4}>
              <AnswerList
                answers={currentQuestion.possibleAnswers}
                onChange={handleSelect}
                selectedAnswer={currentSelected}
                correctAnswer={answer}
                answered={answered}
              />
            </Box>
          </motion.div>
        </Box>
        <motion.div variants={animationVariant} transition={transition(4)}>
          <Stack mt={{ xs: 4 }} alignItems="center">
            <AnimatedButton
              loading={isSubmitting}
              disabled={!currentSelected || isSubmitting}
              onClick={handleSubmit}
            >
              <FormattedMessage
                id={answered ? 'label.proceed' : 'quiz.button.check_answer'}
              />
            </AnimatedButton>
          </Stack>
        </motion.div>
      </Stack>
    </motion.div>
  );
};

export default Question;
