/**
 * import routes as following: "import [name] from './[name]'"
 * & add it to the array of routes
 */

import heatmapLiveEditor from './editor/heatmap/A45Dg56ETvp90';
import guessGame from './guess-game';
import home from './home';
import { Route } from './routes.types';

const routes = [home, guessGame, heatmapLiveEditor];

export default routes as Route[];
