import { ButtonBase, styled } from '@mui/material';

export const Card = styled(ButtonBase)(({ theme }) => ({
  width: 147,
  height: 191,
  display: 'flex',
  flexShrink: 0,
  flexDirection: 'column',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.neutral01[100],
  border: `1px solid ${theme.palette.brand01[100]}`,
  padding: theme.spacing(1.5),
  transition: 'box-shadow 350ms',
  '&:hover': {
    boxShadow: '0px 0px 12px rgba(235, 212, 87, 0.5)',
    '@media (hover: none)': {
      boxShadow: 'none',
    },
  },
}));
