import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoLock = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 50 50" fill="none" {...props}>
    <path
      d="M43.037 50H6V22.7273H43.037V50ZM15.2593 22.7273V13.6364C15.2593 8.61818 19.412 4.54545 24.5185 4.54545C29.625 4.54545 33.7778 8.61818 33.7778 13.6364V22.7273H38.4074V13.6364C38.4074 6.10909 32.1898 0 24.5185 0C16.8472 0 10.6296 6.10909 10.6296 13.6364V22.7273H15.2593Z"
      fill="#EBD457"
    />
  </svg>
);
const Memo = memo(SvgIcoLock);
export default Memo;
