// comment out locales that should not be supported
import '@formatjs/intl-relativetimeformat/locale-data/en';
import '@formatjs/intl-relativetimeformat/locale-data/nl';

import { setDefaultOptions } from 'date-fns';
import { nlBE } from 'date-fns/locale';
import { createIntl, createIntlCache, IntlShape } from 'react-intl';

import config from 'config';

import MESSAGES_EN from './translations/en-UK.json';
import MESSAGES_NL from './translations/nl-BE.json';

const { DEFAULT_LOCALE, DEFAULT_LOCALE_DATE } = config;

const locales = ['nl-BE', 'en-UK'];

const intlCache = createIntlCache();

const intl: { [key: string]: IntlShape } = {
  'nl-BE': createIntl({ locale: 'nl-BE', messages: MESSAGES_NL }, intlCache),
  'en-UK': createIntl({ locale: 'en-UK', messages: MESSAGES_EN }, intlCache),
};

setDefaultOptions({ locale: nlBE });

export { DEFAULT_LOCALE, DEFAULT_LOCALE_DATE, intl, locales };
