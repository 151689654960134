import { QueryKey } from '@tanstack/react-query';

enum Keys {
  Episode = 'episode',
  Pin = 'pin',
  MiniGames = 'mini-games',
  Settings = 'settings',
  GameRules = 'game-rules',
  Participants = 'participants',
  Partner = 'partner',
  ScoreBreakDown = 'score-breakdown',
  Leaderboard = 'leaderboard',
  User = 'user',
  Heatmap = 'heat-map',
}

export const QueryKeys = {
  episode: {
    all: (): QueryKey => [Keys.Episode],
    partner: (episodeId: string): QueryKey => [Keys.Partner, episodeId],
  },
  pin: {
    all: (): QueryKey => [Keys.Pin],
    byEpisode: (id: string): QueryKey => [Keys.Pin, id],
  },
  heatmap: {
    all: (): QueryKey => [Keys.Heatmap],
    byEpisode: (id: string): QueryKey => [Keys.Heatmap, id],
  },

  miniGames: {
    all: (): QueryKey => [Keys.MiniGames],
    byEpisode: (id: string) => [Keys.MiniGames, id],
  },
  settings: {
    gameRules: (): QueryKey => [Keys.Settings, Keys.GameRules],
  },
  participants: (): QueryKey => [Keys.Participants],
  scoreBreakDown: (): QueryKey => [Keys.ScoreBreakDown],
  leaderboard: {
    all: (): QueryKey => [Keys.Leaderboard],
    general: (previous: boolean): QueryKey => [Keys.Leaderboard, previous],
    user: (previous: boolean): QueryKey => [
      Keys.Leaderboard,
      Keys.User,
      previous,
    ],
  },
};
