import { Easing, Tween, update } from '@tweenjs/tween.js';
import { useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { useEpisodes, useUserPin } from 'queries';
import { navigationActions } from 'store/navigation/navigation.slice';

import { useMap } from 'components/@map';

export const usePinPlacement = () => {
  const { nextEpisode } = useEpisodes();

  const { setPin, pinSetError } = useUserPin(nextEpisode?.id);

  const [map] = useMap();
  const didStartLockAnim = useRef(false);

  const dispatch = useDispatch();

  const animateLockMap = useCallback(
    (position: google.maps.LatLngLiteral, onFinish?: () => void) => {
      if (!map) return;

      const cameraOptions: google.maps.CameraOptions = {
        tilt: 0,
        heading: 0,
        zoom: map.getZoom() ?? 5,
        center: {
          lat: map.getCenter()?.lat() ?? 0,
          lng: map.getCenter()?.lng() ?? 0,
        },
      };

      let frame = requestAnimationFrame(animate);

      function animate(time: number) {
        frame = requestAnimationFrame(animate);
        update(time);
      }

      new Tween(cameraOptions) // Create a new tween that modifies 'cameraOptions'.
        .to({ tilt: 65, heading: 360, zoom: 5, center: position }, 10000)
        .easing(Easing.Quartic.InOut) // Use an easing function to make the animation smooth.
        .onUpdate(() => {
          map.moveCamera(cameraOptions);
        })
        .onComplete(() => {
          onFinish?.();
          didStartLockAnim.current = false;
          cancelAnimationFrame(frame);
        })
        .start();
    },
    [map],
  );

  const animateUnlockMap = useCallback(() => {
    if (!map) return;

    const cameraOptions = {
      tilt: map.getTilt(),
      heading: map.getHeading(),
      zoom: map.getZoom(),
    };

    let frame = requestAnimationFrame(animate);

    function animate(time: number) {
      frame = requestAnimationFrame(animate);
      update(time);
    }

    new Tween(cameraOptions) // Create a new tween that modifies 'cameraOptions'.
      .to({ tilt: 0, heading: 0 }, 2000)
      .easing(Easing.Quartic.InOut) // Use an easing function to make the animation smooth.
      .onUpdate(() => {
        map.moveCamera(cameraOptions);
      })
      .onComplete(() => {
        cancelAnimationFrame(frame);
      })
      .start();
  }, [map]);

  const startPinPlacement = useCallback(() => {
    map?.setOptions({
      draggableCursor: 'pointer',
    });

    dispatch(navigationActions.START_PIN_PLACEMENT());
  }, [dispatch, map]);

  const lockPinPlacement = useCallback(
    (position: google.maps.LatLngLiteral, onFinish?: () => void) => {
      map?.setOptions({
        draggableCursor: '',
        gestureHandling: 'none',
        keyboardShortcuts: false,
      });

      dispatch(navigationActions.LOCK_PIN_PLACEMENT());

      setPin(
        { latitude: position.lat, longitude: position.lng },
        {
          onSuccess: () => {
            animateLockMap(position, onFinish);
          },
        },
      );
    },
    [dispatch, animateLockMap, setPin, map],
  );

  const stopPinPlacement = useCallback(() => {
    map?.setOptions({
      draggableCursor: '',
      gestureHandling: 'greedy',
      keyboardShortcuts: true,
    });
    animateUnlockMap();
    dispatch(navigationActions.STOP_PIN_PLACEMENT());
  }, [dispatch, map, animateUnlockMap]);

  return {
    start: startPinPlacement,
    lock: lockPinPlacement,
    stop: stopPinPlacement,
    isLocked: pinSetError?.response?.data['errors'] === 'episode.votes_locked',
  };
};
