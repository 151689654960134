import { Box, Typography } from '@mui/material';

import { IcoCorner } from 'components/@icons/custom';

interface Props {
  step: number;
  totalSteps: number;
}

const StepCounter = ({ step, totalSteps }: Props) => {
  return (
    <Box
      position="relative"
      width={70}
      height={38}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Typography variant="h5Secondary">
        {step}/{totalSteps}
      </Typography>
      <IcoCorner
        fontSize={12}
        style={{ position: 'absolute', top: 0, left: 0 }}
      />
      <IcoCorner
        fontSize={12}
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          transform: 'scale(-1, 1)',
        }}
      />
      <IcoCorner
        fontSize={12}
        style={{
          position: 'absolute',
          bottom: 0,
          right: 0,
          transform: 'scale(-1, -1)',
        }}
      />
      <IcoCorner
        fontSize={12}
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          transform: 'scale(1, -1)',
        }}
      />
    </Box>
  );
};

export default StepCounter;
