import {
  MiniGames,
  PostGuessGameAnswerParams,
  PostGuessGameAnswerResponse,
  PostQuizAnswerParams,
  PostQuizAnswerResponse,
} from 'types/game.types';

import RestApiService from './';

export default {
  getMiniGames(this: RestApiService, userId: string, episodeId: string) {
    return this.api.get<MiniGames>(
      `/users/${userId}/episodes/${episodeId}/mini-games`,
      { authEnabled: true },
    );
  },
  postGuessGameAnswer(
    this: RestApiService,
    { userId, episodeId, coordinateId, coordinates }: PostGuessGameAnswerParams,
  ) {
    return this.api.post<PostGuessGameAnswerResponse>(
      `/users/${userId}/episodes/${episodeId}/mini-games/guess-game/coordinates/${coordinateId}/answer`,
      coordinates,
      { authEnabled: true },
    );
  },
  postQuizAnswer(
    this: RestApiService,
    { userId, episodeId, answerId, questionId }: PostQuizAnswerParams,
  ) {
    return this.api.post<PostQuizAnswerResponse>(
      `/users/${userId}/episodes/${episodeId}/mini-games/quiz/question/${questionId}/answer`,
      { answerId },
      { authEnabled: true },
    );
  },
};
