import { Checkbox } from '@mui/material';
import React from 'react';

import {
  IcoCheckboxChecked,
  IcoCheckboxCorrect,
  IcoCheckboxDefault,
  IcoCheckboxWrong,
} from 'components/@icons';

import { FormControlLabelStyled } from './Answer.style';

interface Props {
  label: string;
  onChange(): void;
  checked: boolean;
  correct?: boolean;
  wrong?: boolean;
  disabled?: boolean;
}

// TO DO: add styles based on whether the answer is correct or not

const Answer: React.FC<Props> = ({
  label,
  onChange,
  checked,
  correct,
  wrong,
  disabled,
}) => {
  const getIcon = () => {
    switch (true) {
      case correct:
        return <IcoCheckboxCorrect />;
      case wrong:
        return <IcoCheckboxWrong />;
      default:
        return <IcoCheckboxDefault />;
    }
  };

  return (
    <FormControlLabelStyled
      wrong={wrong}
      correct={correct}
      label={label}
      control={
        <Checkbox
          icon={getIcon()}
          checked={checked && !wrong && !correct}
          disabled={disabled}
          onChange={onChange}
          checkedIcon={<IcoCheckboxChecked />}
          sx={{ mr: 2.5 }}
        />
      }
    />
  );
};

export default Answer;
