import { Card, CardProps, Stack, StackProps } from '@mui/material';
import { styled } from '@mui/material/styles';

type Props = {
  finished?: boolean;
};

export const CardStyled = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'finished',
})<CardProps & Props>(({ theme, finished }) => ({
  borderRadius: 0,
  outline: '1px solid transparent',
  transition: 'outline .2s ease-out',

  ...(!finished && {
    '&:hover': {
      outline: `1px solid ${theme.palette.primary.main}`,
      boxShadow: '0px 0px 12px rgba(235, 212, 87, 0.5)',
      '@media (hover: none)': {
        boxShadow: 'none',
      },
    },
  }),
}));

export const IconWrap = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'finished',
})<StackProps & Props>(({ theme, finished }) => ({
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  marginBottom: theme.spacing(4),
  '& svg:not(.lock)': {
    opacity: finished ? 0.2 : 1,
  },
}));
