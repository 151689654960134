import { Suspense } from 'react';

import { useSentryRoutes } from 'services/sentry';

import routes from './routes';

const Pages = () => {
  const element = useSentryRoutes(routes);

  return <Suspense fallback={null}>{element}</Suspense>;
};

export default Pages;
