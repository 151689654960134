import { combineReducers } from 'redux';

import authReducer from './auth/auth.slice';
import intlReducer from './intl/intl.slice';
import mapReducer from './map/map.slice';
import contentNavigationReducer from './navigation/navigation.slice';

const reducers = {
  intl: intlReducer,
  map: mapReducer,
  navigation: contentNavigationReducer,
  auth: authReducer,
};

export const rootReducer = combineReducers(reducers);

export type RootReducer = typeof rootReducer;
export type Store = ReturnType<RootReducer>;

export default rootReducer;
