import { Components, Theme } from '@mui/material';

export const MuiCard: Components<Theme>['MuiCard'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      backgroundColor: theme.palette.neutral01[100],
      alignItems: 'center',
      justifyContent: 'space-between',
    }),
  },
};
