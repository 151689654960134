import {
  Episode,
  Participant,
  PartnerParams,
  SelectPartnerParams,
} from 'types/episode.types';
import { Coordinate } from 'types/game.types';

import RestApiService from './';

export default {
  getEpisodes(this: RestApiService) {
    return this.api.get<Episode[]>(`/episodes`);
  },
  getPartner(this: RestApiService, { userId, episodeId }: PartnerParams) {
    return this.api.get<Participant>(
      `/users/${userId}/episodes/${episodeId}/partner`,
      { authEnabled: true },
    );
  },
  selectPartner(
    this: RestApiService,
    { userId, episodeId, participant }: SelectPartnerParams,
  ) {
    return this.api.post<Participant>(
      `/users/${userId}/episodes/${episodeId}/partner`,
      {
        participantId: participant.id,
      },
      { authEnabled: true },
    );
  },
  // Production only
  getProductionEpisodes(this: RestApiService) {
    return this.api.get<Episode[]>(`https://bx.vtm.be/api/episodes`);
  },
  getHeatmap(this: RestApiService, episodeId: string) {
    // Force production api
    return this.api.get<Coordinate[]>(
      `https://bx.vtm.be/api/episodes/${episodeId}/heatmap`,
    );
  },
};
