import { Components, Theme } from '@mui/material';

export const MuiButtonBase: Components<Theme>['MuiButtonBase'] = {
  defaultProps: {
    disableRipple: true,
    disableTouchRipple: true,
  },
  styleOverrides: {
    root: {
      fontFamily: "'Nunito Sans', sans-serif",
    },
  },
};
