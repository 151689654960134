import { Box } from '@mui/material';
import { ReactNode, useCallback, useRef } from 'react';

import { createImage } from '../share.utils';

interface Props {
  children: ReactNode;
  id: string;
  onComplete?: (image: string) => void;
  render: boolean;
}

const ShareRenderer = ({ children, id, onComplete, render }: Props) => {
  const didRender = useRef(false);

  const onRefLoaded = useCallback(
    async (node: HTMLDivElement) => {
      if (node && render) {
        if (didRender.current) {
          return;
        }

        async function renderShare() {
          const image = await createImage(id);

          if (image) {
            onComplete?.(image);
          }
        }

        renderShare();
        didRender.current = true;
      }
    },
    [id, onComplete, render],
  );

  return (
    <Box
      position="absolute"
      top={-99999}
      left={-99999}
      zIndex={-99999}
      display="flex"
      flexDirection="column"
    >
      <Box id={`share-${id}`} ref={onRefLoaded}>
        {children}
      </Box>
    </Box>
  );
};

export default ShareRenderer;
