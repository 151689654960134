import _debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';

export function useWindowSize(debounce = 0) {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<{
    width?: number;
    height?: number;
  }>({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener

    const debouncedHandleResize = _debounce(handleResize, debounce);
    window.addEventListener('resize', debouncedHandleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, [debounce]); // Empty array ensures that effect is only run on mount
  return windowSize;
}
