import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoQuiz = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 59 38" fill="none" {...props}>
    <rect
      x={1.07316}
      y={11.148}
      width={26.5699}
      height={26.5699}
      transform="rotate(-16.145 1.07316 11.148)"
      stroke="white"
      strokeWidth={1.73282}
      strokeLinecap="square"
      strokeLinejoin="bevel"
    />
    <path
      d="M16.8786 26.4675L15.9376 23.2167L19.4155 22.2099L20.3566 25.4607L16.8786 26.4675ZM18.7486 18.0085C19.1395 17.8322 19.4412 17.6312 19.6537 17.4056C19.8628 17.1682 19.9134 16.8632 19.8055 16.4903C19.6976 16.1175 19.461 15.8892 19.0958 15.8055C18.7306 15.7218 18.3383 15.7407 17.9189 15.8621L16.4582 16.2471L15.2274 16.6412C14.7031 16.793 14.3356 17.0067 14.125 17.2824C13.926 17.5546 13.8973 17.9355 14.039 18.4248C14.1166 18.6928 14.1788 18.8642 14.2257 18.939L11.1672 19.8244C10.7355 18.333 10.6608 17.2245 10.9432 16.4987C11.2256 15.7729 12.0659 15.2077 13.464 14.8029L15.8331 14.0224C16.1244 13.9381 16.4348 13.8545 16.7644 13.7717C17.0907 13.6773 17.4303 13.5853 17.7832 13.4958C18.2259 13.3676 18.837 13.2538 19.6164 13.1545C20.3925 13.0435 21.0411 13.1903 21.5624 13.5951C22.0836 13.9998 22.4775 14.6624 22.7439 15.5829L22.8198 15.845C22.975 16.381 23.0682 16.8338 23.0995 17.2036C23.1439 17.5317 23.0677 17.901 22.8709 18.3116C22.6741 18.7221 21.9469 19.242 20.6895 19.8712C19.4321 20.5004 18.8247 20.954 18.8673 21.2321L18.9404 21.4193L15.9344 22.2895C15.5397 20.9263 15.5226 20.06 15.8828 19.6905C16.2514 19.306 17.2066 18.7453 18.7486 18.0085Z"
      fill="white"
    />
    <rect
      x={31.4073}
      y={0.981502}
      width={26.5699}
      height={26.5699}
      transform="rotate(8.22921 31.4073 0.981502)"
      fill="#0D0D0D"
      stroke="#EBD457"
      strokeWidth={1.73282}
      strokeLinecap="square"
      strokeLinejoin="bevel"
    />
    <path
      d="M39.0384 21.1369L39.5228 17.7875L43.1062 18.3058L42.6218 21.6551L39.0384 21.1369ZM44.2326 14.2036C44.6615 14.2044 45.0192 14.1458 45.3059 14.0279C45.5943 13.8981 45.7663 13.641 45.8219 13.2569C45.8774 12.8727 45.7562 12.5672 45.4581 12.3402C45.16 12.1133 44.7948 11.9686 44.3627 11.9061L42.8733 11.6539L41.5896 11.505C41.0493 11.4269 40.6264 11.4699 40.3208 11.634C40.0272 11.7999 39.8439 12.135 39.771 12.6392C39.731 12.9153 39.717 13.0971 39.7289 13.1846L36.5776 12.7288C36.7998 11.1922 37.1893 10.1516 37.746 9.60711C38.3028 9.06258 39.3014 8.89448 40.742 9.10282L43.222 9.36957C43.5221 9.41297 43.8394 9.46498 44.1738 9.5256C44.5099 9.57421 44.8572 9.63056 45.2156 9.69465C45.6718 9.76063 46.2754 9.9092 47.0263 10.1404C47.779 10.3595 48.3092 10.761 48.617 11.3448C48.9248 11.9285 49.0101 12.6946 48.8729 13.643L48.8338 13.9131C48.754 14.4653 48.652 14.9163 48.5279 15.266C48.433 15.5832 48.2112 15.8882 47.8624 16.1809C47.5137 16.4736 46.6368 16.6471 45.2318 16.7013C43.8268 16.7554 43.0863 16.918 43.0104 17.1889L42.9997 17.3896L39.9025 16.9416C40.1056 15.5371 40.4475 14.7409 40.9281 14.553C41.4225 14.3549 42.524 14.2384 44.2326 14.2036Z"
      fill="#EBD457"
    />
  </svg>
);
const Memo = memo(SvgIcoQuiz);
export default Memo;
